input.text-right::-webkit-outer-spin-button,
input.text-right::-webkit-inner-spin-button {
  display: none !important;
}

input.text-right {
  -moz-appearance: textfield !important;
}

.mat-mdc-form-field-icon-suffix {
  padding: 0 5px 0 4px !important;
}

.mat-mdc-input-element{
  font-weight: 600;
}

.mdc-checkbox {
  //padding: calc((var(--mdc-checkbox-state-layer-size) - 18px) / 2);
  margin: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-state-layer-size)) / 6);
  margin-left: -8px!important;
}
.mdc-form-field > label, mat-radio-group mat-radio-button > label {
  padding-left: 2px !important;
  padding-right: 10px ;
  font-weight: 600 !important;
}
.mdc-form-field>label {
  margin-left: 0;
  margin-right: 15px!important;
  padding-left: 4px;
  padding-right: 0;
  order: 0;
}
