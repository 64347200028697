.mat-expansion-panel-body {
  padding: 5px !important;
}

.mat-accordion .mat-expansion-panel {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  //border-bottom: 1px solid #999999;
}

.mat-mdc-tab-header {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  box-shadow: rgba(0, 0, 0, 0.20) 0px 25px 20px -20px;
}
.mat-expansion-panel-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 5px!important;
}
mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none!important;
}
