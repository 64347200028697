@import './variables';

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: 1.25em;
}

.separator:not(:empty)::after {
  margin-left: 1.25em;
}

.border-e2 {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
}

.border-bd {
  border: 1px solid #BDBDBD;
  border-radius: 4px;
}

.border-9e {
  border: 1px solid #9e9e9e;
  border-radius: 4px;
}

.border-gray {
  border: 1px solid $gray;
  border-radius: 4px;
}
.border-gray-deep {
  border: 1px solid $gray-deep!important;
}
.border-gray-dark {
  border: 1px solid $gray-dark!important;
}
.border-primary {
  border: 1px solid $primary;
  border-radius: 4px;
}
.border-primary-2 {
  border: 2px solid $primary;
  border-radius: 4px;
}

.border-warn {
  border: 1px solid $warn;
  border-radius: 4px;
}

.border-h-ea {
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
}

.border-h-r {
  border-right: 1px solid #eaeaea;
}

.border-h-r-black {
  border-right: 1px dotted #555555;
}

.border-t {
  border-top: 1px solid $gray;
}

.border-v {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.border-v-primary {
  border-top: 1px solid $primary;
  border-bottom: 1px solid $primary;
}

.border-b-primary {
  border-bottom: 1px solid $primary;
}
.border-b-primary-2 {
  border-bottom: 2px solid $primary;
}
.border-b-gray {
  border-bottom: 1px solid $gray;
}

.border-t-gray {
  border-top: 1px solid $gray!important;
}

.border-title {
  border-bottom: 1px solid $gray-dark;
  padding-bottom: 5px;
}

.border-dashed-7 {
  border: 1px dashed #777777;
  border-radius: 4px;
}

.border-dashed-9 {
  border: 1px dashed #999999;
  border-radius: 4px;
}

.border-b-dashed {
  border-bottom: 1px dashed #777777;
  padding-bottom: 5px;
}
.border-b-dashed-gray {
  border-bottom: 1px dashed $gray;
  padding-bottom: 5px;
}
.border-b-dashed-gray {
  border-bottom: 1px dashed #999999;
  padding-bottom: 5px;
}

.border-b-e2 {
  border-bottom: 1px solid #e2e2e2;
}
.border-b-33 {
  border-bottom: 1px solid #333333;
}

.border-t-dashed {
  border-top: 1px dashed #777777;
}

.border-shadow {
  background: white;
  box-sizing: border-box;
  opacity: 1;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .12), inset 0 -1px 0 0 #dadce0;
  border-radius: 40px;
}

.border-shadow-light {
  background: white;
  box-sizing: border-box;
  opacity: 1;
  box-shadow: 0 3px 12px rgb(0 0 0 / 5%);
  border-radius: 40px;
}

.border-b-shadow {
  background: white;
  box-sizing: border-box;
  opacity: 1;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .12), inset 0 -1px 0 0 #dadce0;
}
.border-b-card-shadow {
  background: white;
  box-sizing: border-box;
  opacity: 1;
  box-shadow: 0 14px 16px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 0 #dadce0;
}

.border-left-dotted {
  border-left: 1px dotted #212121;
}

.border-left-solid {
  border-left: 1px solid #212121;
}
.border-left-primary {
  border-left: 2px solid $primary;
}
.border-left-primary-shot {
  border-left: 4px solid $primary;
  padding-bottom: 10px!important;
}

.border-left-mint {
  border-left: 2px solid $mint;
}
.border-left-warn {
  border-left: 2px solid $warn;
}

.border-t-radius-20 {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.border-t-radius-40 {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.border-none {
  border: none !important;
}

.brd-5 {
  border-radius: 5px;
}

.brd-10 {
  border-radius: 10px!important;
}

.brd-15 {
  border-radius: 15px!important;
}

.brd-20 {
  border-radius: 20px!important;
}

.brd-30 {
  border-radius: 30px!important;
}
.brd-20-left {
  border-top-left-radius: 20px!important;
  border-bottom-left-radius: 20px!important;
}
.brd-20-right {
  border-top-right-radius: 20px!important;
  border-bottom-right-radius: 20px!important;
}

.brd-40 {
  border-radius: 40px!important;
}

.brd-50 {
  border-radius: 50px!important;
}

.shadow-box-none {
  box-shadow: none !important;
}

.shadow-box {
  box-shadow: 10px 10px 10px #ccc;
}

.shadow-box-xs {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.shadow-box-xxs {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .12), inset 0 -1px 0 0 #dadce0;
}
.point-y-12px {
  height:fit-content(100%)!important;
  width: 10px!important;
}
