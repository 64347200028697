// Color

$primary-light: rgba(19, 125, 197, 0.15);
$primary: #158cdc;
$primary-darker: #0d5789;

$amber-light: #ffe592;
$amber: #FFD244;
$amber-darker: #ffc302;

//$accent-light: #ddecf6;
$accent-light: rgba(19, 125, 197, 0.15);
$accent: #158cdc;
$accent-darker: #0d5789;


$warn-light: #f5c4c8;
$warn: #F46A70;
$warn-darker: #f1414a;

//$warn-light: #f79360;
//$warn: #f5793b;
//$warn-darker: #f35f16;

$gray: #999999;
$gray-light: #f6f7f7;
$gray-deep: #666666;
$gray-dark: #212121;

$green-light: #92e3cc;
$green: #0d8d56;
$green-darker: #095e3a;

//$green-light: #92e3cc;
//$green: #11bc72;
//$green-darker: #0d8d56;

//$mint-light: #84e0e0;
//$mint: #33cccc;
//$mint-darker: #2db7b7;

$brown-light: #ebdedd;
$brown: #734743;
$brown-darker: #5a3835;

$mint-lighter: #c5f0ef;
$mint-light: #b1ebea;
$mint: #33cccc;
//$mint: #49ccdb;
$mint-darker: #2db7b7;


$purple-light: #d2c5d8;
$purple: #9855b4;
$purple-darker: #83459c;

//$indigo-light: #BFACC8;
$indigo-light: #ede9f0;
$indigo: #7b6b9c;
$indigo-darker: #5e4873;

$pink-light : #f0b7d2;
$pink : #DB5291;
$pink-darker : #c54982;

$orange-darker : #ff7900;
$orange : #ff8314;
$orange-light : #ffa24e;



$accent-sky : rgba(19, 125, 197, 0.4);
