.mat-mdc-dialog-content, .mdc-dialog__content {
  color: #212121 !important;
  padding-bottom: 60px !important;
  line-height: 1.8 !important;
}

.mat-mdc-raised-button.mat-mdc-button-base {
  height: 40px !important;
}

.mdc-button__label {
  font-weight: 600 !important;
}

@media (max-width: 400px) {
  .mat-mdc-dialog-content, .mdc-dialog__content {
    padding-bottom: 30px !important;
  }
}

.mat-mdc-dialog-container .mat-mdc-dialog-content {
  font-size: 0.8rem !important;
}
