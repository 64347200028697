@import './variables';

button {
  box-shadow: none !important;
  font-weight: 600;
  padding-top: 25px;
  padding-bottom: 25px;
}

button:not(.mat-fab):not(.mat-menu-item):not(.swal2-styled):not(.button-mini):not(.mat-mini-fab):not(.mat-icon-button) {
  line-height: 25px;
}

.button-line-lg {
  min-width: 65px;
  padding: 13px 18px!important;
  flex-direction: column;
  align-items: center;
  border-radius: 28px;
  border: 1px solid #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.26px;
}
.button-line-xs {
  min-width: 45px;
  padding: 8px 16px!important;
  flex-direction: column;
  align-items: center;
  border-radius: 28px;
  border: 1px solid #FFF;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.26px;
}

.button-line-xs-accent {
  min-width: 45px;
  padding: 6px 16px!important;
  flex-direction: column;
  align-items: center;
  border-radius: 28px;
  border: 1px solid $accent;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.26px;
  color: $accent;
}

.button-mini-icon {
  border-radius: 20px !important;
  font-size: 13px !important;
  line-height: 13px !important;
  padding: 6px 15px !important;
  color: #333333;
  border: 1px solid #dadada;
}

.button-mini-w {
  border-radius: 20px !important;
  font-size: 12px !important;
  line-height: 12px !important;
  padding: 6px 8px !important;
  background-color: white !important;
  font-weight: 500;
  color: #212121;
}


.button-mini {
  border-radius: 20px !important;
  font-size: 12px!important;
  line-height: 12px;
  padding: 9px 15px;
  font-weight: 600;
  border: 1px solid inherit;
}

.button-md {
  font-size: 14px !important;
  padding: 20px 0!important;
  line-height: 14px;
  font-weight: 700;
  box-shadow: none !important;
}

.button-lg {
  padding: 25px 0 !important;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  box-shadow: none !important;
}

.button-suffix {
  height: 32px;
  margin-top: 10px !important;
  font-size: 13px !important;
  border: 1px solid $gray !important;
  color: $gray;
  line-height: 30px !important;
}

.now-change {
  min-width: 35px;
  padding: 5px 12px 6px 12px;
  border-radius: 20px;
  border: 1px solid $accent;
  background-color: $accent;
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600!important;
  text-align: center;
}

.now-change-none {
  min-width: 35px;
  padding: 5px 12px 6px 12px;
  border-radius: 20px;
  border: 1px solid inherit;
  background-color: $accent;
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600!important;
  text-align: center;
}

.accent-chip-bg {
  min-width: 35px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  border: 1px solid $accent-light;
  background-color: $accent-light;
  color: #212121;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.primary-bg-chip {
  width: fit-content;
  min-width: 35px;
  padding: 4px 9px 6px 9px;
  border-radius: 20px;
  border: 1px solid $primary;
  background-color: $primary;
  color: #ffffff;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  text-align: center;
  opacity: .8;
}


.border-chip {
  min-width: 35px;
  padding: 6px 12px;
  border-radius: 20px;
  border: 1px solid $accent;
  color: $accent;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  text-align: center!important;
}

.gray-bg-chip {
  min-width: 35px;
  padding: 6px 12px!important;
  border-radius: 20px;
  background-color: #eaeaea;
  color: #212121;
  font-size: 12px;
  line-height: 16px!important;
  font-weight: 600;
  text-align: center!important;
}
//.bg-chip-xs {
//  min-width: 35px;
//  padding: 6px 10px!important;
//  border-radius: 20px;
//  background-color: $accent;
//  color: #ffffff;
//  font-size: 11px;
//  line-height: 14px;
//  font-weight: 600;
//  text-align: center!important;
//}
.gray-bg-chip-mint {
  min-width: 35px;
  padding: 6px 10px!important;
  border-radius: 20px;
  background-color: $mint-darker;
  color: #ffffff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  text-align: center;
}

.gray-bg-chip-mint-lg {
  min-width: 35px;
  padding: 12px 12px!important;
  border-radius: 20px;
  background-color: $mint-darker;
  color: #ffffff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  text-align: center;
}
.gray-bg-chip-primary-lg {
  min-width: 35px;
  padding: 12px 12px!important;
  border-radius: 20px;
  background-color: $primary;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.gray-chip-bg {
  min-width: 35px;
  padding: 5px 12px 6px 12px;
  border-radius: 20px;
  border: 1px solid #777777;
  background-color: #777777;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
}

.accent-chip-bg {
  min-width: 35px;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 20px;
  border: 1px solid $accent;
  background-color: $accent;
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.gray-chip {
  width: fit-content;
  min-width: 35px;
  padding: 6px 12px;
  border-radius: 20px;
  border: 1px solid #666666;
  color: #666666;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.gray-chip-open {
  width: fit-content;
  min-width: 35px;
  padding: 5px 12px 6px 12px;
  border-radius: 20px;
  border: 1px solid $gray-dark;
  color: $gray-dark;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.gray-chip-bg {
  width: fit-content;
  min-width: 35px;
  padding: 5px 12px 6px 12px;
  border-radius: 30px;
  border: 1px solid $gray-light;
  background-color: $gray-light;
  color: #212121;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.gray-chip-lg {
  width: fit-content;
  min-width: 35px;
  padding: 5px 9px 6px 9px;
  border-radius: 20px;
  border: 1px solid #666666;
  color: #666666;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.primary-bg-chip {
  min-width: 45px;
  padding: 4px 9px 5px 9px;
  border-radius: 20px;
  background-color: $primary;
  color: #ffffff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 500;
  text-align: center;
}

.send-bg-chip {
  min-width: 45px;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: $primary;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
}

.receive-bg-chip {
  min-width: 45px;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: #eaeaea;
  color: #212121;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
}

.border-inherit {
  border: inherit !important;
}

.circle-step-warn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #F31623;
  text-align: center !important;
  line-height: 60px;
  font-size: 13px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border: 1px solid #F31623;
}

.circle-step-black {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #212121;
  text-align: center !important;
  line-height: 60px;
  font-size: 13px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border: 1px solid #212121;
}

.circle-step-bg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #212121;
  text-align: center !important;
  line-height: 60px;
  font-size: 14px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  background: #eaeaea;
}

.circle-step-border {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #4283ef;
  text-align: center !important;
  line-height: 60px;
  font-size: 14px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border: 2px solid #4283ef;
}

.circle-step-bg-lg {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  color: #ffffff;
  text-align: center !important;
  line-height: 90px;
  font-size: 15px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  background: #eaeaea;
}

.circle-step-border-lg {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  color: #4283ef;
  text-align: center !important;
  line-height: 90px;
  font-size: 15px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border: 2px solid #4283ef;
}

.cube-step-bg {
  border-radius: 20px;
  color: #212121;
  text-align: center !important;
  font-size: 14px;
  padding: 20px 15px !important;
  font-weight: 600;
  vertical-align: middle !important;
  background: #fafafa;
}

.circle-step-black-dot {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #212121;
  text-align: center !important;
  line-height: 60px;
  font-size: 13px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border: 1px dotted #212121;
}

.icon-circle-wrapper-xs {
  width: 70px;
  border-radius: 20px;
  line-height: 12px;
  padding: 6px 10px;
  background-color: #eaeaea;
  color: #212121;
  font-size: 12px;
  vertical-align: middle;
  font-weight: 600;
  text-align: center;
}

.icon-circle-wrapper-xs-primary {
  width: 66px;
  padding: 6px 2px;
  border-radius: 20px;
  line-height: 12px;
  border: 1px solid $primary-darker;
  color: $primary-darker;
  font-size: 12px;
  vertical-align: middle;
  font-weight: 600;
  text-align: center;
}

.mat-badge-accent .mat-badge-content {
  background: $accent !important;
  color: white !important;
}

.img-help {
  padding: 6px 10px;
  border-radius: 4px;
  background-color: $gray-dark;
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: $primary !important;
  background-color: $primary !important;
}

.mdc-button {
  padding: 0 13px;
}

.flow-chip {
  width: fit-content;
  min-width: 35px;
  padding: 6px 15px 7px 15px!important;
  border-radius: 20px;
  background-color: #eaeaea;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.flow-chip-now {
  width: fit-content;
  min-width: 35px;
  padding: 6px 15px 7px 15px!important;
  border-radius: 20px;
  background-color: $gray-dark!important;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}
.flow-chip-accent-now {
  width: fit-content;
  min-width: 35px;
  padding: 6px 15px 7px 15px!important;
  border-radius: 20px;
  background-color: $accent!important;
color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.ana-chip-primary {
  padding: 6px 10px;
  border-radius: 20px;
  background-color: $primary;
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}
.btn-border-2 {
  border: 2px #1f1f1f solid!important;
  font-size: 15px!important;
  font-weight: 700!important;
}
