// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.
@use 'sass:map';
@use '@angular/material' as mat;

// Note: Color palettes are generated from primary: #654e7c, secondary: #ffc206
$_palettes: (
  primary: (
    0: #000000,
    10: #2c0051,
    20: #431a6b,
    25: #4f2777,
    30: #5b3383,
    35: #673f90,
    40: #654e7c,
    50: #8e65b8,
    60: #a97ed4,
    70: #c499f1,
    80: #dcb8ff,
    90: #f0dbff,
    95: #f9ecff,
    98: #fff7fe,
    99: #fffbff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #251a00,
    20: #3f2e00,
    25: #4d3800,
    30: #5b4300,
    35: #694e00,
    40: #785a00,
    50: #977100,
    60: #b68a00,
    70: #d7a300,
    80: #f9bd00,
    90: #ffdf9c,
    95: #ffefd3,
    98: #fff8f2,
    99: #fffbff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #321017,
    20: #4b252b,
    25: #582f36,
    30: #653a41,
    35: #73464c,
    40: #805158,
    50: #9c6a70,
    60: #b88389,
    70: #d59ca3,
    80: #f3b7be,
    90: #ffd9dd,
    95: #ffeced,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #1d1b1e,
    20: #322f33,
    25: #3d3a3e,
    30: #49464a,
    35: #555155,
    40: #615d61,
    50: #7a767a,
    60: #948f94,
    70: #afaaae,
    80: #cbc5c9,
    90: #e7e1e5,
    95: #f5eff4,
    98: #fef7fc,
    99: #fffbff,
    100: #ffffff,
    4: #0f0d11,
    6: #151316,
    12: #211f22,
    17: #2c292d,
    22: #373437,
    24: #3b383c,
    87: #ded8dd,
    92: #ede6eb,
    94: #f3ecf1,
    96: #f8f2f6,
  ),
  neutral-variant: (
    0: #000000,
    10: #1e1a22,
    20: #332f37,
    25: #3f3942,
    30: #4a454e,
    35: #565059,
    40: #625c65,
    50: #7b757e,
    60: #968e98,
    70: #b1a9b3,
    80: #ccc4ce,
    90: #e9dfeb,
    95: #f7eef9,
    98: #fff7fe,
    99: #fffbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
  ),
  typography: (
    plain-family: 'Noto Sans KR',
    brand-family: 'Noto Sans KR'
  ),
));

$dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $_primary,
    tertiary: $_tertiary,
  ),
  typography: (
    plain-family: 'Noto Sans KR',
    brand-family: 'Noto Sans KR'
  ),
));


@include mat.core();
:root {
  @include mat.all-component-themes($light-theme);
}






:root {
  --mat-app-background-color: #fffbff;
  --mat-app-text-color: #1d1b1e;
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-ripple-color: rgba(29, 27, 30, 0.1);
  --mat-option-selected-state-label-text-color: #ffffff;
  --mat-option-label-text-color: #1d1b1e;
  --mat-option-hover-state-layer-color: rgba(29, 27, 30, 0.08);
  --mat-option-focus-state-layer-color: rgba(29, 27, 30, 0.12);
  --mat-option-selected-state-layer-color: #158cdc;
  --mat-option-label-text-font: Noto Sans KR;
  --mat-option-label-text-line-height: 1.25rem;
  //--mat-option-label-text-size: 1rem;
  --mat-option-label-text-size: 0.875rem;
  --mat-option-label-text-tracking: 0.006rem;
  --mat-option-label-text-weight: 400;
  --mat-optgroup-label-text-color: #0d5789;
  --mat-optgroup-label-text-font: Noto Sans KR;
  --mat-optgroup-label-text-line-height: 1.25rem;
  --mat-optgroup-label-text-size: 0.875rem;
  --mat-optgroup-label-text-tracking: 0.006rem;
  --mat-optgroup-label-text-weight: 600;
  --mat-full-pseudo-checkbox-selected-icon-color: #158cdc;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #ffffff;
  --mat-full-pseudo-checkbox-unselected-icon-color: #0d5789;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fffbff;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: rgba(29, 27, 30, 0.38);
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: rgba(29, 27, 30, 0.38);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #158cdc;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: rgba(29, 27, 30, 0.38);
  --mdc-elevated-card-container-color: #fffbff;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 12px;
  --mdc-outlined-card-container-color: #fffbff;
  --mdc-outlined-card-outline-color: #ccc4ce;
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-shape: 12px;
  --mdc-outlined-card-outline-width: 1px;
  --mat-card-subtitle-text-color: #1d1b1e;
  --mat-card-title-text-font: Noto Sans KR;
  --mat-card-title-text-line-height: 1.75rem;
  //--mat-card-title-text-size: 1.375rem;
  //--mat-card-title-text-size: 18px;
  --mat-card-title-text-size: 16px;
  --mat-card-title-text-tracking: 0rem;
  --mat-card-title-text-weight: 400;
  --mat-card-subtitle-text-font: Noto Sans KR;
  --mat-card-subtitle-text-line-height: 1.5rem;
  --mat-card-subtitle-text-size: 1rem;
  --mat-card-subtitle-text-tracking: 0.009rem;
  --mat-card-subtitle-text-weight: 500;
  --mdc-linear-progress-active-indicator-color: #158cdc;
  --mdc-linear-progress-track-color: #e9dfeb;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0px;
  --mdc-plain-tooltip-container-color: #322f33;
  --mdc-plain-tooltip-supporting-text-color: #f5eff4;
  --mdc-plain-tooltip-supporting-text-line-height: 1rem;
  --mdc-plain-tooltip-supporting-text-font: Noto Sans KR;
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.025rem;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #158cdc;
  --mdc-filled-text-field-focus-active-indicator-color: #158cdc;
  --mdc-filled-text-field-focus-label-text-color: #158cdc;
  //--mdc-filled-text-field-container-color: #e9dfeb;
  --mdc-filled-text-field-container-color: rgba(29, 27, 30, 0);
  --mdc-filled-text-field-disabled-container-color: rgba(29, 27, 30, 0.04);
  --mdc-filled-text-field-label-text-color: #212121;
  --mdc-filled-text-field-hover-label-text-color: #0d5789;
  --mdc-filled-text-field-disabled-label-text-color: rgba(29, 27, 30, 0.38);
  --mdc-filled-text-field-input-text-color: #1d1b1e;
  --mdc-filled-text-field-disabled-input-text-color: rgba(29, 27, 30, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: #0d5789;
  --mdc-filled-text-field-error-hover-label-text-color: #410002;
  --mdc-filled-text-field-error-focus-label-text-color: #ba1a1a;
  --mdc-filled-text-field-error-label-text-color: #ba1a1a;
  --mdc-filled-text-field-active-indicator-color: #999999;
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(29, 27, 30, 0.38);
  --mdc-filled-text-field-hover-active-indicator-color: #1d1b1e;
  --mdc-filled-text-field-error-active-indicator-color: #ba1a1a;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ba1a1a;
  --mdc-filled-text-field-error-hover-active-indicator-color: #410002;
  --mdc-filled-text-field-label-text-font: Noto Sans KR;

  //--mdc-filled-text-field-label-text-size: 1rem;
  --mdc-filled-text-field-label-text-size: .875rem;

  --mdc-filled-text-field-label-text-tracking: 0.031rem;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-caret-color: #158cdc;
  --mdc-outlined-text-field-focus-outline-color: #212121;
  --mdc-outlined-text-field-focus-label-text-color: #158cdc;
  --mdc-outlined-text-field-label-text-color: #212121;
  --mdc-outlined-text-field-hover-label-text-color: #1d1b1e;
  --mdc-outlined-text-field-disabled-label-text-color: rgba(29, 27, 30, 0.38);
  --mdc-outlined-text-field-input-text-color: #1d1b1e;
  --mdc-outlined-text-field-disabled-input-text-color: rgba(29, 27, 30, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: #999999;
  --mdc-outlined-text-field-error-focus-label-text-color: #ba1a1a;
  --mdc-outlined-text-field-error-label-text-color: #ba1a1a;
  --mdc-outlined-text-field-error-hover-label-text-color: #410002;
  --mdc-outlined-text-field-outline-color: #7b757e;
  --mdc-outlined-text-field-disabled-outline-color: rgba(29, 27, 30, 0.12);
  --mdc-outlined-text-field-hover-outline-color: #1d1b1e;
  --mdc-outlined-text-field-error-focus-outline-color: #ba1a1a;
  --mdc-outlined-text-field-error-hover-outline-color: #410002;
  --mdc-outlined-text-field-error-outline-color: #ba1a1a;
  --mdc-outlined-text-field-label-text-font: Noto Sans KR;
  //--mdc-outlined-text-field-label-text-size: 1rem;
  --mdc-outlined-text-field-label-text-size: 0.87rem;
  --mdc-outlined-text-field-label-text-tracking: 0.031rem;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mat-form-field-focus-select-arrow-color: #158cdc;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(29, 27, 30, 0.38);
  --mat-form-field-state-layer-color: #1d1b1e;
  --mat-form-field-error-text-color: #ba1a1a;
  --mat-form-field-select-option-text-color: #1d1b1e;
  --mat-form-field-select-disabled-option-text-color: rgba(29, 27, 30, 0.38);
  --mat-form-field-leading-icon-color: #0d5789;
  --mat-form-field-disabled-leading-icon-color: rgba(29, 27, 30, 0.38);
  --mat-form-field-trailing-icon-color: #999999;
  --mat-form-field-disabled-trailing-icon-color: rgba(29, 27, 30, 0.38);
  --mat-form-field-error-focus-trailing-icon-color: #ba1a1a;
  --mat-form-field-error-hover-trailing-icon-color: #410002;
  --mat-form-field-error-trailing-icon-color: #ba1a1a;
  --mat-form-field-enabled-select-arrow-color: #0d5789;
  --mat-form-field-disabled-select-arrow-color: rgba(29, 27, 30, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-container-text-font: Noto Sans KR;
  --mat-form-field-container-text-line-height: 1.5rem;

  --mat-form-field-container-text-size: 0.9rem;

  --mat-form-field-container-text-tracking: 0.031rem;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-subscript-text-font: Noto Sans KR;
  --mat-form-field-subscript-text-line-height: 1rem;
  --mat-form-field-subscript-text-size: 0.75rem;
  --mat-form-field-subscript-text-tracking: 0.025rem;
  --mat-form-field-subscript-text-weight: 400;
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
  --mat-form-field-focus-state-layer-opacity: 0;
  //--mat-select-panel-background-color: #f3ecf1;
  --mat-select-panel-background-color: #ddecf6;
  --mat-select-enabled-trigger-text-color: #1d1b1e;
  --mat-select-disabled-trigger-text-color: rgba(29, 27, 30, 0.38);
  --mat-select-placeholder-text-color: #0d5789;
  --mat-select-enabled-arrow-color: #0d5789;
  --mat-select-disabled-arrow-color: rgba(29, 27, 30, 0.38);
  --mat-select-focused-arrow-color: #158cdc;
  --mat-select-invalid-arrow-color: #ba1a1a;
  --mat-select-trigger-text-font: Noto Sans KR;
  --mat-select-trigger-text-line-height: 1.5rem;
  //--mat-select-trigger-text-size: 1rem;
  --mat-select-trigger-text-size: 0.875rem;
  --mat-select-trigger-text-tracking: 0.031rem;
  //--mat-select-trigger-text-weight: 400;
  --mat-select-trigger-text-weight: 600;
  --mat-select-arrow-transform: translateY(-8px);
  --mat-select-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #f3ecf1;
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  //--mdc-dialog-container-color: #fffbff;
  --mdc-dialog-container-color: #ffffff;
  --mdc-dialog-subhead-color: #1d1b1e;
  --mdc-dialog-supporting-text-color: #0d5789;
  --mdc-dialog-subhead-font: Noto Sans KR;
  --mdc-dialog-subhead-line-height: 2rem;
  --mdc-dialog-subhead-size: 1.5rem;
  --mdc-dialog-subhead-weight: 600;
  --mdc-dialog-subhead-tracking: 0rem;
  --mdc-dialog-supporting-text-font: Noto Sans KR;
  --mdc-dialog-supporting-text-line-height: 1.25rem;
  --mdc-dialog-supporting-text-size: 0.875rem;
  --mdc-dialog-supporting-text-weight: 500;
  --mdc-dialog-supporting-text-tracking: 0.016rem;
  --mdc-dialog-container-shape: 10px;
  --mat-dialog-container-elevation-shadow: none;
  --mat-dialog-container-max-width: 560px;
  --mat-dialog-container-small-max-width: calc(100vw - 32px);
  --mat-dialog-container-min-width: 280px;
  --mat-dialog-actions-alignment: flex-end;
  //--mat-dialog-actions-padding: 16px 24px;
  --mat-dialog-actions-padding: 20px 24px;
  --mat-dialog-content-padding: 20px 24px;
  //--mat-dialog-with-actions-content-padding: 20px 24px 0;
  --mat-dialog-with-actions-content-padding: 20px 30px 0;
  //--mat-dialog-headline-padding: 25px 24px 13px;
  --mat-dialog-headline-padding: 25px 24px 20px;
  --mdc-chip-outline-color: #158cdc;
  --mdc-chip-disabled-outline-color: rgba(29, 27, 30, 0.12);
  --mdc-chip-focus-outline-color: #0d5789;
  --mdc-chip-hover-state-layer-opacity: 0.08;
  --mdc-chip-selected-hover-state-layer-opacity: 0.08;
  --mdc-chip-disabled-label-text-color: rgba(29, 27, 30, 0.38);
  --mdc-chip-elevated-selected-container-color: #33cccc;
  --mdc-chip-flat-disabled-selected-container-color: rgba(29, 27, 30, 0.12);
  --mdc-chip-focus-state-layer-color: #0d5789;
  --mdc-chip-hover-state-layer-color: #0d5789;
  --mdc-chip-selected-hover-state-layer-color: #251a00;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: #251a00;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #0d5789;
  --mdc-chip-selected-label-text-color: #251a00;
  --mdc-chip-with-icon-icon-color: #0d5789;
  --mdc-chip-with-icon-disabled-icon-color: #1d1b1e;
  --mdc-chip-with-icon-selected-icon-color: #251a00;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #1d1b1e;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #0d5789;
  --mdc-chip-label-text-font: Noto Sans KR;
  --mdc-chip-label-text-line-height: 1.25rem;
  --mdc-chip-label-text-size: 0.875rem;
  --mdc-chip-label-text-tracking: 0.006rem;
  --mdc-chip-label-text-weight: 500;
  --mdc-chip-container-height: 32px;
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 8px;
  --mdc-chip-with-avatar-avatar-size: 24px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 1px;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 0.38;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 0.38;
  --mdc-chip-with-icon-disabled-icon-opacity: 0.38;
  --mat-chip-trailing-action-state-layer-color: #0d5789;
  --mat-chip-selected-trailing-action-state-layer-color: #251a00;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0.08;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0.12;
  --mat-chip-selected-disabled-trailing-icon-color: #1d1b1e;
  --mat-chip-selected-trailing-icon-color: #251a00;
  --mat-chip-disabled-container-opacity: 1;
  --mat-chip-trailing-action-opacity: 1;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.08;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.08;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-selected-focus-state-layer-color: #158cdc;
  --mdc-switch-selected-handle-color: #ffffff;
  --mdc-switch-selected-hover-state-layer-color: #158cdc;
  --mdc-switch-selected-pressed-state-layer-color: #158cdc;
  --mdc-switch-selected-focus-handle-color: #ddecf6;
  --mdc-switch-selected-hover-handle-color: #ddecf6;
  --mdc-switch-selected-pressed-handle-color: #ddecf6;
  --mdc-switch-selected-focus-track-color: #158cdc;
  --mdc-switch-selected-hover-track-color: #158cdc;
  --mdc-switch-selected-pressed-track-color: #158cdc;
  --mdc-switch-selected-track-color: #158cdc;
  --mdc-switch-disabled-selected-handle-color: #fffbff;
  --mdc-switch-disabled-selected-icon-color: #1d1b1e;
  --mdc-switch-disabled-selected-track-color: #1d1b1e;
  --mdc-switch-disabled-unselected-handle-color: #1d1b1e;
  --mdc-switch-disabled-unselected-icon-color: #e9dfeb;
  --mdc-switch-disabled-unselected-track-color: #e9dfeb;
  --mdc-switch-selected-icon-color: #ffffff;
  --mdc-switch-unselected-focus-handle-color: #158cdc;
  --mdc-switch-unselected-focus-state-layer-color: #1d1b1e;
  --mdc-switch-unselected-focus-track-color: #e9dfeb;
  --mdc-switch-unselected-handle-color: #7b757e;
  --mdc-switch-unselected-hover-handle-color: #158cdc;
  --mdc-switch-unselected-hover-state-layer-color: #1d1b1e;
  --mdc-switch-unselected-hover-track-color: #e9dfeb;
  --mdc-switch-unselected-icon-color: #e9dfeb;
  --mdc-switch-unselected-pressed-handle-color: #158cdc;
  --mdc-switch-unselected-pressed-state-layer-color: #1d1b1e;
  --mdc-switch-unselected-pressed-track-color: #e9dfeb;
  --mdc-switch-unselected-track-color: #e9dfeb;
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-shape: 9999px;
  --mdc-switch-selected-icon-size: 16px;
  --mdc-switch-track-height: 32px;
  --mdc-switch-track-shape: 9999px;
  --mdc-switch-track-width: 52px;
  --mdc-switch-unselected-icon-size: 16px;
  --mdc-switch-state-layer-size: 40px;
  --mat-switch-track-outline-color: #7b757e;
  --mat-switch-disabled-unselected-track-outline-color: #1d1b1e;
  --mat-switch-label-text-color: #1d1b1e;
  --mat-switch-label-text-font: Noto Sans KR;
  --mat-switch-label-text-line-height: 1.25rem;
  --mat-switch-label-text-size: 0.875rem;
  --mat-switch-label-text-tracking: 0.016rem;
  --mat-switch-label-text-weight: 500;
  --mat-switch-disabled-selected-handle-opacity: 1;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 16px;
  --mat-switch-selected-handle-size: 24px;
  --mat-switch-pressed-handle-size: 28px;
  --mat-switch-with-icon-handle-size: 24px;
  --mat-switch-selected-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0 22px;
  --mat-switch-unselected-handle-horizontal-margin: 0 8px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 4px;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0 2px;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 0;
  --mat-switch-visible-track-transition: opacity 75ms;
  --mat-switch-hidden-track-transition: opacity 75ms;
  --mat-switch-track-outline-width: 2px;
  --mat-switch-selected-track-outline-width: 2px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 2px;
  --mdc-radio-disabled-selected-icon-color: #1d1b1e;
  --mdc-radio-disabled-unselected-icon-color: #1d1b1e;
  --mdc-radio-unselected-hover-icon-color: #1d1b1e;
  --mdc-radio-unselected-icon-color: #0d5789;
  --mdc-radio-unselected-pressed-icon-color: #1d1b1e;
  --mdc-radio-selected-focus-icon-color: #158cdc;
  --mdc-radio-selected-hover-icon-color: #158cdc;
  --mdc-radio-selected-icon-color: #158cdc;
  --mdc-radio-selected-pressed-icon-color: #158cdc;
  //--mdc-radio-state-layer-size: 40px;
  --mdc-radio-state-layer-size: 40px;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mat-radio-ripple-color: #1d1b1e;
  --mat-radio-checked-ripple-color: #158cdc;
  --mat-radio-disabled-label-color: rgba(29, 27, 30, 0.38);
  --mat-radio-label-text-color: #1d1b1e;
  --mat-radio-label-text-font: Noto Sans KR;
  --mat-radio-label-text-line-height: 1.25rem;
  //--mat-radio-label-text-size: 0.875rem;
  --mat-radio-label-text-size: 0.82rem;
  --mat-radio-label-text-tracking: 0.016rem;
  --mat-radio-label-text-weight: 500;
  --mat-radio-touch-target-display: block;
  --mdc-slider-handle-color: #158cdc;
  --mdc-slider-focus-handle-color: #158cdc;
  --mdc-slider-hover-handle-color: #158cdc;
  --mdc-slider-active-track-color: #158cdc;
  --mdc-slider-inactive-track-color: #e9dfeb;
  --mdc-slider-with-tick-marks-inactive-container-color: #0d5789;
  --mdc-slider-with-tick-marks-active-container-color: #ffffff;
  --mdc-slider-disabled-active-track-color: #1d1b1e;
  --mdc-slider-disabled-handle-color: #1d1b1e;
  --mdc-slider-disabled-inactive-track-color: #1d1b1e;
  --mdc-slider-label-container-color: #158cdc;
  --mdc-slider-label-label-text-color: #ffffff;
  --mdc-slider-with-overlap-handle-outline-color: #ffffff;
  --mdc-slider-with-tick-marks-disabled-container-color: #1d1b1e;
  --mdc-slider-handle-elevation: 1;
  --mdc-slider-handle-shadow-color: #000000;
  --mdc-slider-label-label-text-font: Noto Sans KR;
  --mdc-slider-label-label-text-size: 0.75rem;
  --mdc-slider-label-label-text-line-height: 1rem;
  --mdc-slider-label-label-text-tracking: 0.031rem;
  --mdc-slider-label-label-text-weight: 500;
  --mdc-slider-active-track-height: 4px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 9999px;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.38;
  --mdc-slider-with-tick-marks-container-shape: 9999px;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.38;
  --mat-slider-ripple-color: #158cdc;
  --mat-slider-hover-state-layer-color: rgba(116, 76, 157, 0.05);
  --mat-slider-focus-state-layer-color: rgba(116, 76, 157, 0.2);
  --mat-slider-value-indicator-width: 28px;
  --mat-slider-value-indicator-height: 28px;
  --mat-slider-value-indicator-caret-display: none;
  --mat-slider-value-indicator-border-radius: 50% 50% 50% 0;
  --mat-slider-value-indicator-padding: 0;
  --mat-slider-value-indicator-text-transform: rotate(45deg);
  --mat-slider-value-indicator-container-transform: translateX(-50%) rotate(-45deg);
  --mat-slider-value-indicator-opacity: 1;
  --mat-menu-item-label-text-color: #1d1b1e;
  --mat-menu-item-icon-color: #0d5789;
  --mat-menu-item-hover-state-layer-color: rgba(29, 27, 30, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(29, 27, 30, 0.12);
  --mat-menu-container-color: #ffffff;
  --mat-menu-divider-color: #ddecf6;
  --mat-menu-item-label-text-font: Noto Sans KR;
  --mat-menu-item-label-text-size: 0.875rem;
  --mat-menu-item-label-text-tracking: 0.006rem;
  --mat-menu-item-label-text-line-height: 1.25rem;
  --mat-menu-item-label-text-weight: 500;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 8px;
  --mat-menu-divider-top-spacing: 8px;
  --mat-menu-item-spacing: 12px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 12px;
  --mat-menu-item-trailing-spacing: 12px;
  --mat-menu-item-with-icon-leading-spacing: 12px;
  --mat-menu-item-with-icon-trailing-spacing: 12px;
  --mat-menu-base-elevation-level: 2;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: #ddecf6;
  --mdc-list-list-item-disabled-state-layer-color: #1d1b1e;
  --mdc-list-list-item-disabled-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-color: #1d1b1e;
  --mdc-list-list-item-supporting-text-color: #0d5789;
  --mdc-list-list-item-leading-icon-color: #0d5789;
  --mdc-list-list-item-trailing-supporting-text-color: #0d5789;
  --mdc-list-list-item-trailing-icon-color: #0d5789;
  --mdc-list-list-item-selected-trailing-icon-color: #158cdc;
  --mdc-list-list-item-disabled-label-text-color: #1d1b1e;
  --mdc-list-list-item-disabled-leading-icon-color: #1d1b1e;
  --mdc-list-list-item-disabled-trailing-icon-color: #1d1b1e;
  --mdc-list-list-item-hover-label-text-color: #1d1b1e;
  --mdc-list-list-item-focus-label-text-color: #1d1b1e;
  --mdc-list-list-item-hover-state-layer-color: #1d1b1e;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: #1d1b1e;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-font: Noto Sans KR;
  --mdc-list-list-item-label-text-line-height: 1.5rem;
  //--mdc-list-list-item-label-text-size: 1rem;
  --mdc-list-list-item-label-text-size: 0.82rem;
  --mdc-list-list-item-label-text-tracking: 0.031rem;
  --mdc-list-list-item-label-text-weight: 600;
  --mdc-list-list-item-supporting-text-font: Noto Sans KR;
  --mdc-list-list-item-supporting-text-line-height: 1.25rem;
  --mdc-list-list-item-supporting-text-size: 0.875rem;
  --mdc-list-list-item-supporting-text-tracking: 0.016rem;
  --mdc-list-list-item-supporting-text-weight: 500;
  --mdc-list-list-item-trailing-supporting-text-font: Noto Sans KR;
  --mdc-list-list-item-trailing-supporting-text-line-height: 1rem;
  --mdc-list-list-item-trailing-supporting-text-size: 0.688rem;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.031rem;
  --mdc-list-list-item-trailing-supporting-text-weight: 500;
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mdc-list-list-item-container-shape: 0px;
  --mdc-list-list-item-leading-avatar-shape: 9999px;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-label-text-opacity: 0.3;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: #33cccc;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 16px;
  --mat-list-active-indicator-shape: 9999px;
  --mat-paginator-container-text-color: #1d1b1e;
  //--mat-paginator-container-background-color: #fffbff;
  --mat-paginator-container-background-color: #f6f7f7!important;
  --mat-paginator-enabled-icon-color: #0d5789;
  --mat-paginator-disabled-icon-color: rgba(29, 27, 30, 0.38);
  --mat-paginator-container-text-font: Noto Sans KR;
  --mat-paginator-container-text-line-height: 1rem;
  --mat-paginator-container-text-size: 0.75rem;
  --mat-paginator-container-text-tracking: 0.025rem;
  --mat-paginator-container-text-weight: 500;
  --mat-paginator-select-trigger-text-size: 0.75rem;
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
  //--mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-secondary-navigation-tab-container-height: 58px;
  //--mdc-tab-indicator-active-indicator-color: #158cdc;
  --mdc-tab-indicator-active-indicator-color: #158cdc;
  //--mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-height: 3px;
  //--mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-tab-indicator-active-indicator-shape: 20px;
  --mat-tab-header-divider-color: #e9dfeb;
  --mat-tab-header-pagination-icon-color: #1d1b1e;
  --mat-tab-header-inactive-label-text-color: #1d1b1e;
  //--mat-tab-header-active-label-text-color: #158cdc;
  --mat-tab-header-active-label-text-color: #158cdc;
  --mat-tab-header-active-ripple-color: #1d1b1e;
  --mat-tab-header-inactive-ripple-color: #1d1b1e;
  --mat-tab-header-inactive-focus-label-text-color: #1d1b1e;
  --mat-tab-header-inactive-hover-label-text-color: #1d1b1e;
  --mat-tab-header-active-focus-label-text-color: #158cdc;
  --mat-tab-header-active-hover-label-text-color: #158cdc;
  --mat-tab-header-active-focus-indicator-color: #158cdc;
  --mat-tab-header-active-hover-indicator-color: #158cdc;
  //--mat-tab-header-label-text-font: Noto Sans KR;
  --mat-tab-header-label-text-font: 'S-CoreDream-6Bold', serif;
  --mat-tab-header-label-text-size: 0.875rem;
  --mat-tab-header-label-text-tracking: 0.006rem;
  --mat-tab-header-label-text-line-height: 1.25rem;
  //--mat-tab-header-label-text-weight: 500;
  --mat-tab-header-label-text-weight: 600;
  --mat-tab-header-divider-height: 1px;

  --mdc-checkbox-disabled-selected-checkmark-color: #fffbff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-disabled-selected-icon-color: rgba(29, 27, 30, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(29, 27, 30, 0.38);
  --mdc-checkbox-selected-checkmark-color: #ffffff;
  --mdc-checkbox-selected-focus-icon-color: #158cdc;
  --mdc-checkbox-selected-hover-icon-color: #158cdc;
  --mdc-checkbox-selected-icon-color: #158cdc;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: #212121;
  --mdc-checkbox-selected-focus-state-layer-color: #158cdc;
  --mdc-checkbox-selected-hover-state-layer-color: #158cdc;
  --mdc-checkbox-selected-pressed-state-layer-color: #1d1b1e;
  --mdc-checkbox-unselected-focus-state-layer-color: #1d1b1e;
  --mdc-checkbox-unselected-hover-state-layer-color: #1d1b1e;
  --mdc-checkbox-unselected-pressed-state-layer-color: #158cdc;
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-disabled-label-color: rgba(29, 27, 30, 0.38);
  --mat-checkbox-label-text-color: #1d1b1e;
  --mat-checkbox-label-text-font: Noto Sans KR;
  --mat-checkbox-label-text-line-height: 1.25rem;

  //--mat-checkbox-label-text-size: 0.875rem;
  --mat-checkbox-label-text-size: 0.85rem;

  --mat-checkbox-label-text-tracking: 0.016rem;
  --mat-checkbox-label-text-weight: 500;
  --mat-checkbox-touch-target-display: block;
  --mdc-text-button-label-text-color: #158cdc;
  --mdc-text-button-disabled-label-text-color: rgba(29, 27, 30, 0.38);
  --mdc-text-button-label-text-font: Noto Sans KR;
  --mdc-text-button-label-text-size: 0.875rem;

  --mdc-text-button-label-text-tracking: 0.006rem;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-container-height: 40px;
  --mdc-text-button-container-shape: 9999px;
  //--mdc-protected-button-container-color: #fffbff;
  --mdc-protected-button-container-color: #158cdc;

  //--mdc-protected-button-label-text-color: #654e7c;
  --mdc-protected-button-label-text-color: #ffffff;
  --mdc-protected-button-disabled-container-color: rgba(29, 27, 30, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(29, 27, 30, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000000;
  --mdc-protected-button-label-text-font: Noto Sans KR;
  --mdc-protected-button-label-text-size: 0.875rem;
  --mdc-protected-button-label-text-tracking: 0.006rem;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-container-height: 40px;
  --mdc-protected-button-container-shape: 9999px;
  //--mdc-filled-button-container-color: #654e7c;
  --mdc-filled-button-container-color: #212121;
  --mdc-filled-button-label-text-color: #ffffff;
  --mdc-filled-button-disabled-container-color: rgba(29, 27, 30, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(29, 27, 30, 0.38);
  --mdc-filled-button-label-text-font: Noto Sans KR;
  --mdc-filled-button-label-text-size: 0.875rem;
  --mdc-filled-button-label-text-tracking: 0.006rem;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-container-height: 40px;
  --mdc-filled-button-container-shape: 9999px;
  --mdc-outlined-button-disabled-outline-color: rgba(29, 27, 30, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(29, 27, 30, 0.38);
  //--mdc-outlined-button-label-text-color: #654e7c;
  --mdc-outlined-button-label-text-color: #555555;
  --mdc-outlined-button-outline-color: #555555;
  --mdc-outlined-button-label-text-font: Noto Sans KR;
  --mdc-outlined-button-label-text-size: 0.875rem;
  --mdc-outlined-button-label-text-tracking: 0.006rem;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-container-height: 40px;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 9999px;
  --mat-text-button-state-layer-color: #158cdc;
  --mat-text-button-disabled-state-layer-color: #158cdc;
  --mat-text-button-ripple-color: rgba(116, 76, 157, 0.12);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mat-text-button-touch-target-display: block;
  --mat-text-button-horizontal-padding: 12px;
  --mat-text-button-with-icon-horizontal-padding: 16px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: -4px;
  --mat-protected-button-state-layer-color: #158cdc;
  --mat-protected-button-disabled-state-layer-color: #0d5789;
  --mat-protected-button-ripple-color: rgba(116, 76, 157, 0.12);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mat-protected-button-touch-target-display: block;
  //--mat-protected-button-horizontal-padding: 24px;
  --mat-protected-button-horizontal-padding: 12px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -8px;
  --mat-filled-button-state-layer-color: #ffffff;
  --mat-filled-button-disabled-state-layer-color: #4a454e;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.12);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mat-filled-button-touch-target-display: block;
  --mat-filled-button-horizontal-padding: 24px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -8px;
  --mat-outlined-button-state-layer-color: #158cdc;
  --mat-outlined-button-disabled-state-layer-color: #0d5789;
  --mat-outlined-button-ripple-color: rgba(116, 76, 157, 0.12);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mat-outlined-button-touch-target-display: block;
  --mat-outlined-button-horizontal-padding: 24px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -8px;
  --mdc-icon-button-icon-color: #158cdc;
  --mdc-icon-button-disabled-icon-color: rgba(29, 27, 30, 0.38);
  --mdc-icon-button-state-layer-size: 40px;
  --mdc-icon-button-icon-size: 24px;
  --mat-icon-button-state-layer-color: #0d5789;
  --mat-icon-button-disabled-state-layer-color: #0d5789;
  --mat-icon-button-ripple-color: rgba(74, 69, 78, 0.12);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mat-icon-button-touch-target-display: block;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000000;
  --mdc-extended-fab-label-text-font: Noto Sans KR;
  --mdc-extended-fab-label-text-size: 0.875rem;
  --mdc-extended-fab-label-text-tracking: 0.006rem;
  --mdc-extended-fab-label-text-weight: 500;
  --mdc-extended-fab-container-height: 56px;
  --mdc-extended-fab-container-shape: 16px;
  --mdc-fab-container-color: #ddecf6;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000000;
  --mdc-fab-container-shape: 16px;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-color: #ddecf6;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000000;
  --mdc-fab-small-container-shape: 12px;
  --mdc-fab-small-icon-size: 24px;
  --mat-fab-foreground-color: #2c0051;
  --mat-fab-state-layer-color: #2c0051;
  --mat-fab-ripple-color: rgba(44, 0, 81, 0.12);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(29, 27, 30, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(29, 27, 30, 0.38);
  --mat-fab-touch-target-display: block;
  --mat-fab-small-foreground-color: #2c0051;
  --mat-fab-small-state-layer-color: #2c0051;
  --mat-fab-small-ripple-color: rgba(44, 0, 81, 0.12);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(29, 27, 30, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(29, 27, 30, 0.38);
  --mdc-snackbar-container-color: #322f33;
  --mdc-snackbar-supporting-text-color: #f5eff4;
  --mdc-snackbar-supporting-text-font: Noto Sans KR;
  --mdc-snackbar-supporting-text-line-height: 1.25rem;
  --mdc-snackbar-supporting-text-size: 0.875rem;
  --mdc-snackbar-supporting-text-weight: 500;
  --mdc-snackbar-container-shape: 4px;
  --mat-snack-bar-button-color: #158cdc;
  --mat-table-background-color: #ffffff;
  --mat-table-header-headline-color: #1d1b1e;
  --mat-table-row-item-label-text-color: #1d1b1e;
  --mat-table-row-item-outline-color: #ccc4ce;
  --mat-table-header-headline-font: Noto Sans KR;
  --mat-table-header-headline-line-height: 1.25rem;
  --mat-table-header-headline-size: 0.875rem;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.006rem;
  --mat-table-row-item-label-text-font: Noto Sans KR;
  --mat-table-row-item-label-text-line-height: 1.25rem;
  //--mat-table-row-item-label-text-size: 0.875rem;
  --mat-table-row-item-label-text-size: 13px;
  --mat-table-row-item-label-text-weight: 500;
  --mat-table-row-item-label-text-tracking: 0.016rem;
  --mat-table-footer-supporting-text-font: Noto Sans KR;
  --mat-table-footer-supporting-text-line-height: 1.25rem;
  --mat-table-footer-supporting-text-size: 0.875rem;
  --mat-table-footer-supporting-text-weight: 500;
  --mat-table-footer-supporting-text-tracking: 0.016rem;
  //--mat-table-header-container-height: 56px;
  --mat-table-header-container-height: 40px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
  --mat-table-row-item-outline-width: 1px;
  --mdc-circular-progress-active-indicator-color: #158cdc;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mat-badge-background-color: #ba1a1a;
  --mat-badge-text-color: #ffffff;
  --mat-badge-disabled-state-background-color: rgba(186, 26, 26, 0.38);
  --mat-badge-disabled-state-text-color: #ffffff;
  --mat-badge-text-font: Noto Sans KR;
  --mat-badge-text-size: 0.688rem;
  --mat-badge-text-weight: 500;
  --mat-badge-large-size-text-size: 0.688rem;
  --mat-badge-container-shape: 9999px;
  --mat-badge-container-size: 16px;
  --mat-badge-small-size-container-size: 6px;
  --mat-badge-large-size-container-size: 16px;
  --mat-badge-legacy-container-size: unset;
  --mat-badge-legacy-small-size-container-size: unset;
  --mat-badge-legacy-large-size-container-size: unset;
  --mat-badge-container-offset: -12px 0;
  --mat-badge-small-size-container-offset: -6px 0;
  --mat-badge-large-size-container-offset: -12px 0;
  --mat-badge-container-overlap-offset: -12px;
  --mat-badge-small-size-container-overlap-offset: -6px;
  --mat-badge-large-size-container-overlap-offset: -12px;
  --mat-badge-container-padding: 0 4px;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0 4px;
  --mat-badge-small-size-text-size: 0;
  --mat-bottom-sheet-container-text-color: #1d1b1e;
  --mat-bottom-sheet-container-background-color: #f8f2f6;
  --mat-bottom-sheet-container-text-font: Noto Sans KR;
  --mat-bottom-sheet-container-text-line-height: 1.5rem;
  --mat-bottom-sheet-container-text-size: 1rem;
  --mat-bottom-sheet-container-text-tracking: 0.031rem;
  --mat-bottom-sheet-container-text-weight: 500;
  --mat-bottom-sheet-container-shape: 28px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.08;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-standard-button-toggle-text-color: #1d1b1e;
  --mat-standard-button-toggle-state-layer-color: #1d1b1e;
  --mat-standard-button-toggle-selected-state-background-color: #84e0e0;
  //--mat-standard-button-toggle-selected-state-text-color: #251a00;
  --mat-standard-button-toggle-selected-state-text-color: #ffffff;

  --mat-standard-button-toggle-disabled-state-text-color: rgba(29, 27, 30, 0.38);
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(29, 27, 30, 0.38);
  --mat-standard-button-toggle-disabled-selected-state-background-color: rgba(29, 27, 30, 0.12);
  --mat-standard-button-toggle-divider-color: #7b757e;
  --mat-standard-button-toggle-label-text-font: Noto Sans KR;
  --mat-standard-button-toggle-label-text-line-height: 1.25rem;
  --mat-standard-button-toggle-label-text-size: 0.875rem;
  --mat-standard-button-toggle-label-text-tracking: 0.006rem;
  --mat-standard-button-toggle-label-text-weight: 500;
  --mat-standard-button-toggle-height: 40px;
  --mat-standard-button-toggle-shape: 9999px;
  --mat-standard-button-toggle-background-color: transparent;
  --mat-standard-button-toggle-disabled-state-background-color: transparent;
  --mat-datepicker-calendar-date-selected-state-text-color: #ffffff;
  --mat-datepicker-calendar-date-selected-state-background-color: #158cdc;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(29, 27, 30, 0.38);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #158cdc;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(29, 27, 30, 0.12);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(29, 27, 30, 0.08);
  --mat-datepicker-toggle-active-state-icon-color: #4a454e;
  --mat-datepicker-calendar-date-in-range-state-background-color: #f0dbff;
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: #f6f7f7;
  //--mat-datepicker-calendar-date-in-overlap-range-state-background-color: #ffdf9c;
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #ddecf6;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #158cdc;
  --mat-datepicker-toggle-icon-color: #4a454e;
  --mat-datepicker-calendar-body-label-text-color: #1d1b1e;
  --mat-datepicker-calendar-period-button-text-color: #4a454e;
  --mat-datepicker-calendar-period-button-icon-color: #4a454e;
  --mat-datepicker-calendar-navigation-button-icon-color: #4a454e;
  --mat-datepicker-calendar-header-text-color: #4a454e;
  --mat-datepicker-calendar-date-today-outline-color: #158cdc;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(29, 27, 30, 0.38);
  --mat-datepicker-calendar-date-text-color: #1d1b1e;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(29, 27, 30, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: #158cdc;
  --mat-datepicker-range-input-separator-color: #1d1b1e;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(29, 27, 30, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(29, 27, 30, 0.38);
  --mat-datepicker-calendar-container-background-color: #ede6eb;
  --mat-datepicker-calendar-container-text-color: #1d1b1e;
  --mat-datepicker-calendar-text-font: Noto Sans KR;
  --mat-datepicker-calendar-text-size: 1rem;
  --mat-datepicker-calendar-body-label-text-size: 0.875rem;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 0.875rem;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 0.875rem;
  --mat-datepicker-calendar-header-text-weight: 500;
  --mat-datepicker-calendar-container-shape: 16px;
  --mat-datepicker-calendar-container-touch-shape: 28px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-divider-color: transparent;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-divider-color: #ccc4ce;
  --mat-divider-width: 1px;
  //--mat-expansion-container-background-color: #fffbff;
  --mat-expansion-container-background-color: #ffffff;
  --mat-expansion-container-text-color: #1d1b1e;
  --mat-expansion-actions-divider-color: #ccc4ce;
  --mat-expansion-header-hover-state-layer-color: rgba(29, 27, 30, 0.08);
  --mat-expansion-header-focus-state-layer-color: rgba(29, 27, 30, 0.12);
  --mat-expansion-header-disabled-state-text-color: rgba(29, 27, 30, 0.38);
  --mat-expansion-header-text-color: #1d1b1e;
  --mat-expansion-header-description-color: #0d5789;
  --mat-expansion-header-indicator-color: #0d5789;
  --mat-expansion-header-text-font: Noto Sans KR;
  //--mat-expansion-header-text-size: 1rem;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 600;
  --mat-expansion-header-text-line-height: 1.5rem;
  --mat-expansion-header-text-tracking: 0.009rem;
  --mat-expansion-container-text-font: Noto Sans KR;
  --mat-expansion-container-text-line-height: 1.5rem;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.031rem;
  --mat-expansion-container-text-weight: 500;
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
  //--mat-expansion-container-shape: 12px;
  --mat-expansion-container-shape: 1px;
  --mat-expansion-legacy-header-indicator-display: none;
  --mat-expansion-header-indicator-display: inline-block;
  --mat-grid-list-tile-header-primary-text-size: 400 1rem / 1.5rem Noto Sans KR;
  --mat-grid-list-tile-header-secondary-text-size: 400 0.875rem / 1.25rem Noto Sans KR;
  --mat-grid-list-tile-footer-primary-text-size: 400 1rem / 1.5rem Noto Sans KR;
  --mat-grid-list-tile-footer-secondary-text-size: 400 0.875rem / 1.25rem Noto Sans KR;
  --mat-icon-color: inherit;
  --mat-sidenav-container-background-color: #fffbff;
  --mat-sidenav-container-text-color: #0d5789;
  --mat-sidenav-content-background-color: #fffbff;
  --mat-sidenav-content-text-color: #1d1b1e;
  --mat-sidenav-scrim-color: rgba(51, 47, 55, 0.4);
  --mat-sidenav-container-shape: 16px;
  --mat-sidenav-container-elevation-shadow: none;
  --mat-sidenav-container-width: 360px;
  --mat-sidenav-container-divider-color: transparent;
  --mat-stepper-header-icon-foreground-color: #fffbff;
  //--mat-stepper-header-selected-state-icon-background-color: #158cdc;
  --mat-stepper-header-selected-state-icon-background-color: #158cdc;
  --mat-stepper-header-selected-state-icon-foreground-color: #ffffff;
  //--mat-stepper-header-edit-state-icon-background-color: #158cdc;
  --mat-stepper-header-edit-state-icon-background-color: #14b2ae;
  --mat-stepper-header-edit-state-icon-foreground-color: #ffffff;
  //--mat-stepper-container-color: #fffbff;
  --mat-stepper-container-color: #ffffff;

  --mat-stepper-line-color: #ccc4ce;
  --mat-stepper-header-hover-state-layer-color: rgba(50, 47, 51, 0.08);
  --mat-stepper-header-focus-state-layer-color: rgba(50, 47, 51, 0.12);
  --mat-stepper-header-label-text-color: #212121;
  --mat-stepper-header-optional-label-text-color: #212121;
  --mat-stepper-header-selected-state-label-text-color: #212121;
  //--mat-stepper-header-error-state-label-text-color: #ba1a1a;
  --mat-stepper-header-error-state-label-text-color: #f1414a;
  //--mat-stepper-header-icon-background-color: #4a454e;
  --mat-stepper-header-icon-background-color: #999999;
  //--mat-stepper-header-error-state-icon-foreground-color: #ba1a1a;
  --mat-stepper-header-error-state-icon-foreground-color: #f1414a;
  --mat-stepper-container-text-font: Noto Sans KR;
  --mat-stepper-header-label-text-font: Noto Sans KR;
  --mat-stepper-header-label-text-size: 0.9rem;
  --mat-stepper-header-label-text-weight: 600;
  --mat-stepper-header-error-state-label-text-size: 0.875rem;
  --mat-stepper-header-selected-state-label-text-size: 0.875rem;
  --mat-stepper-header-selected-state-label-text-weight: 500;
  --mat-stepper-header-height: 72px;
  --mat-stepper-header-focus-state-layer-shape: 12px;
  --mat-stepper-header-hover-state-layer-shape: 12px;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #1d1b1e;
  --mat-toolbar-container-background-color: #fffbff;
  --mat-toolbar-container-text-color: #1d1b1e;
  --mat-toolbar-title-text-font: Noto Sans KR;
  --mat-toolbar-title-text-line-height: 1.75rem;
  --mat-toolbar-title-text-size: 1.375rem;
  --mat-toolbar-title-text-tracking: 0rem;
  --mat-toolbar-title-text-weight: 500;
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
  --mat-tree-container-background-color: #fffbff;
  --mat-tree-node-text-color: #1d1b1e;
  --mat-tree-node-text-font: Noto Sans KR;
  --mat-tree-node-text-size: 1rem;
  --mat-tree-node-text-weight: 500;
  --mat-tree-node-min-height: 48px;
}
