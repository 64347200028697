@import "./variables";

.bg-black {
  background: #1f1f1f !important;
  color: white;
}

.bg-white {
  background: white !important;
  color: #1F1F1F;
}

.bg-gray-fa {
  background-color: #fafafa !important;
  color: #1F1F1F!important;
}

.bg-gray-ea {
  background-color: #eaeaea !important;
  color: #1F1F1F !important;
}

.bg-gray-da {
  background-color: #dadada !important;
  color: #1F1F1F!important;
}

.bg-gray-light {
  background-color: $gray-light !important;
  color: #1f1f1f !important;
}

.bg-01 {
  background-color: #ffffff;
}

.bg-02 {
  background-color: #F6F8FE;
}

.bg-03 {
  background-color: #1C3BC0;
}

.bg-04 {
  // background-color: #1564E0;
  background-color: #213EBB;
}

.bg-gray-light-shadow {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 14px 16px 0 rgba(0, 0, 0, 0.2), inset 0 0px 0 0 #dadce0;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
}

.bg-blue1-100 {
  background-color: #EDF0FC;
}
.c-blue1-100 {
  color: #EDF0FC;
}
.bg-blue1-200 {
  background-color: #E0E5FA;
}
.c-blue1-200 {
  color: #E0E5FA;
}
.bg-blue1-300 {
  background-color: #B3C0F4;
}
.c-blue1-300 {
  color: #B3C0F4;
}
.bg-blue2-100 {
  background-color: #EDF0FC!important;
  color: $accent!important;
}
.bg-blue2-200 {
  background-color: #E0E5FA!important;
  color: $accent!important;
}
.bg-blue-04 {
  background-color: #E0E5FA;
}
.bg-gray {
  color: white !important;
  background-color: $gray !important;
}

.bg-gray-deep {
  color: white !important;
  background-color: $gray-deep !important;
}

.bg-gray-dark {
  color: white !important;
  background-color: $gray-dark !important;
}

.bg-primary-light {
  background: $primary-light !important;
  color: #2b2b2b !important;
}

.bg-primary-darker {
  background: $primary-darker !important;
  color: white !important;
}

.bg-primary {
  background: $primary !important;
  color: white !important;
}

.bg-accent {
  // background: $accent !important;
  background: $primary !important;
  color: white !important;
}

.bg-accent-light {
  background: $accent-light !important;
  color: #212121;
}

.bg-accent-darker {
  background: #052457 !important;
  color: white !important;
}

.bg-warn-darker {
  background-color: $warn-darker !important;
  color: white !important;
}

.bg-warn {
  background-color: $warn !important;
  color: white !important;
}

.bg-pink {
  background-color: $pink !important;
  color: white !important;
}

.bg-pink-darker {
  background-color: $pink-darker !important;
  color: white !important;
}

.bg-pink-light {
  background-color: $pink-light !important;
}

.bg-mint {
  background-color: $mint !important;
  color: white !important;
}
.bg-mint-light {
  background-color: $mint-light !important;
}
.bg-mint-darker {
  background-color: $mint-darker !important;
  color: white !important;
}

.c-mint {
  color: $mint !important;
}
.c-mint-darker {
  color: $mint-darker !important;
}
.c-warn-darker {
  color: $warn-darker !important;
}

.c-warn {
  color: $warn !important;
}

.bg-amber {
  background-color: $amber !important;
  color: #212121;
}

.bg-amber-darker {
  background-color: $amber-darker !important;
  color: #212121!important;
}

.c-amber {
  color: $amber !important;
}
.c-amber-darker {
  color: $amber-darker !important;
}

.bg-white-blur {
  background: rgba(255, 255, 255, 0.1) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2) !important;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.bg-white-shadow {
  background: #ffffff !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid inherit !important;
}

.bg-gr-header-01 {
  background: linear-gradient(90deg, #062E70 28.05%, #031A3F 98.55%);
  color: white;
}


.bg-brown-light {
  background-color: $brown-light !important;
}

.bg-brown {
  background-color: $brown !important;
}

.bg-brown-darker {
  background-color: $brown-darker !important;
}

.c-brown {
  color: $brown !important;
}

.c-brown-darker {
  color: $brown-darker !important;
}

.bg-purple {
  background-color: $purple !important;
  color: white;
}

.bg-purple-darker {
  background-color: $purple-darker !important;
  color: white;
}

.bg-purple-light {
  background-color: $purple-light !important;
}

.bg-indigo {
  background-color: $indigo !important;
  color: white;
}

.bg-indigo-darker {
  background-color: $indigo-darker !important;
  color: white;
}

.bg-indigo-light {
  background-color: $indigo-light !important;
}

.c-indigo {
  color: $indigo;
}

.c-indigo-darker {
  color: $indigo-darker;
}

.grade-amber {
  width: 40px;
  height: 40px;
  font-size: 12px;
  padding: 1px 7px 3px 7px;
  border-radius: 50px;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $amber-darker;
}

.grade-amber-icon {
  width: 40px;
  height: 40px;
  font-size: 12px;
  padding: 1px 5px 3px 5px;
  border-radius: 50px;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $amber-darker;
}

.grade-amber-xs {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 4px 3px 4px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $amber-darker;
}

.grade-primary {
  width: 40px;
  height: 40px;
  font-size: 12px;
  padding: 1px 7px 3px 7px;
  border-radius: 50px;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $primary;
}
.grade-primary-icon {
  width: 40px;
  height: 40px;
  font-size: 12px;
  padding: 1px 5px 3px 5px;
  border-radius: 50px;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $primary;
}
.grade-primary-xs {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 4px 3px 4px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $primary;
}

.grade-primary-info {
  width: 10px;
  height: 5px;
  padding: 1px 10px;
  border-radius: 50px;
  margin-right: 3px;
  background-color: $primary;
}

.grade-pink {
  width: 40px;
  height: 40px;
  font-size: 12px;
  padding: 1px 7px 3px 7px;
  border-radius: 50px;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $pink;
}
.grade-pink-icon {
  width: 40px;
  height: 40px;
  font-size: 12px;
  padding: 1px 5px 3px 5px;
  border-radius: 50px;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $pink;
}
.grade-pink-xs {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 4px 3px 4px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $pink;
}

.grade-pink-info {
  width: 10px;
  height: 5px;
  padding: 1px 10px;
  border-radius: 50px;
  margin-right: 3px;
  background-color: $pink;
}

.grade-warn {
  width: 40px;
  height: 40px;
  font-size: 12px;
  padding: 1px 7px 3px 7px;
  border-radius: 50px;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $warn;
}
.grade-warn-icon {
  width: 40px;
  height: 40px;
  font-size: 12px;
  padding: 1px 5px 3px 5px;
  border-radius: 50px;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $warn;
}
.grade-warn-xs {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 4px 3px 4px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $warn;
}

.grade-warn-info {
  width: 10px;
  height: 5px;
  padding: 1px 10px;
  border-radius: 50px;
  margin-right: 3px;
  background-color: $warn;
}

.grade-warn-darker {
  width: 40px;
  height: 40px;
  font-size: 12px;
  padding: 1px 7px 3px 7px;
  border-radius: 50px;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $warn-darker;
}

.grade-warn-darker-icon {
  width: 40px;
  height: 40px;
  font-size: 12px;
  padding: 1px 5px 3px 5px;
  border-radius: 50px;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $warn-darker;
}

.grade-warn-darker-xs {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 4px 3px 4px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $warn-darker;
}

.grade-warn-darker-info {
  width: 10px;
  height: 5px;
  padding: 1px 10px;
  border-radius: 50px;
  margin-right: 3px;
  background-color: $warn-darker;
}

.grade-gray {
  width: 40px;
  height: 40px;
  font-size: 12px;
  padding: 1px 7px 3px 7px;
  border-radius: 50px;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $gray;
}
.grade-gray-icon {
  width: 40px;
  height: 40px;
  font-size: 12px;
  padding: 1px 5px 3px 5px;
  border-radius: 50px;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $gray;
}
.grade-gray-xs {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 4px 3px 4px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $gray;
}

.grade-gray-info {
  width: 10px;
  height: 5px;
  padding: 1px 10px;
  border-radius: 50px;
  margin-right: 3px;
  background-color: $gray;
}

.grade-accent {
  width: 40px;
  height: 40px;
  font-size: 12px;
  padding: 1px 7px 3px 7px;
  border-radius: 50px;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $accent;
}
.grade-accent-icon {
  width: 40px;
  height: 40px;
  font-size: 12px;
  padding: 1px 5px 3px 5px;
  border-radius: 50px;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $accent;
}
.grade-accent-xs {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 4px 3px 4px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $accent;
}


.bg-badge {
  background-color: #f0000d;
  color: #ffffff;
}


.mat-horizontal-stepper-header-container {
  background-color: #f3f5f7!important;
  margin: 0 20px 20px;
}

@media screen and (max-width: 959px) {
  .mat-horizontal-stepper-header-container {
    margin: 0;
  }
}


.bg-info-header {
  background: linear-gradient(91deg, #1C73E8 -23.29%, var(--blue-180007249-c, #062E70) 98.81%);
}


.bg-chip-lg {
  min-width: 35px;
  padding: 9px 15px 9px!important;
  border-radius: 20px;
  background-color: $gray-dark;
  color: #ffffff;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
  text-align: center;
}

.bg-chip-xs {
  min-width: 35px;
  padding: 7px 10px 8px 10px!important;
  border-radius: 30px;
  background-color: $gray;
  color: #ffffff;
  font-size: 11px;
  line-height: 12px;
  font-weight: 600 !important;
  text-align: center;
}
