@import './variables';

.bg-black {
  background: black !important;
  color: white;
}

.bg-smoke {
  background: whitesmoke !important;
}

.bg-white {
  background: white !important;
}

.bg-gray-fa {
  background-color: #fafafa !important;
}

.bg-gray-ea {
  background-color: #eaeaea !important;
}

.bg-gray-da {
  background-color: #dadada !important;
}

.bg-gray-ca {
  background-color: #cacaca !important;
}

.bg-gray-ba {
  background-color: #bababa !important;
}

.bg-gray-light {
  background-color: $gray-light !important;
  color: #333333 !important;
}

.bg-gray-light-shadow {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 14px 16px 0 rgba(0, 0, 0, 0.2), inset 0 0px 0 0 #dadce0;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
}

.bg-gray {
  color: white !important;
  background-color: $gray !important;
}

.bg-gray-deep {
  color: white !important;
  background-color: $gray-deep !important;
}

.bg-gray-dark {
  color: white !important;
  background-color: $gray-dark !important;

}

.bg-gray-33 {
  color: white;
  background-color: #333333;

}

.bg-primary-light-medium {
  background: $primary-light !important;
  color: #212121 !important;
}

.bg-primary-light {
  background: $primary-light !important;
  color: #212121 !important;
}

.bg-amber-light {
  background: rgba(255, 213, 79, 0.2) !important;
  color: #212121 !important;
}

.bg-primary-darker {
  background: $primary-darker !important;
  color: white !important;
}

.bg-primary {
  background: $primary !important;
  color: white !important;
}

.bg-accent {
  background: $accent !important;
  color: white !important;
}

.bg-accent-light {
  background: $accent-light !important;
  color: #212121;
}

.bg-accent-darker {
  background: $accent-darker !important;
  color: white !important;
}

.bg-warn-light {
  background-color: $warn-light !important;
  color: white !important;
}

.bg-warn-darker {
  background-color: $warn-darker !important;
  color: white !important;
}

.bg-warn {
  background-color: $warn !important;
  color: white !important;
}

.bg-pink {
  background-color: $pink !important;
  color: white !important;
}

.bg-pink-darker {
  background-color: $pink-darker !important;
  color: white !important;
}

.bg-pink-light {
  background-color: $pink-light !important;
}

.bg-mint {
  background-color: $mint !important;
  color: white !important;
}

.bg-mint-light {
  background-color: $mint-light !important;
}
.bg-mint-lighter {
  background-color: $mint-lighter !important;
}
.c-mint {
  color: $mint !important;
}

.c-mint-light {
  color: $mint-light !important;
}

.c-gr-primary-mint {
  background-image: linear-gradient(to right, $primary, $mint);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-primary-accent {
  background-image: linear-gradient(to right, $primary, $accent);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-primary-amber {
  background-image: linear-gradient(to right, $primary, $amber);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-warn-primary {
  background-image: linear-gradient(to right, $warn, $primary);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.c-gr-white-warn {
  background-image: linear-gradient(to right, #ffffff, $warn);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.c-gr-white-accentdarker {
  background-image: linear-gradient(to right, #ffffff, $accent-darker);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.c-gr-mint-green {
  background-image: linear-gradient(to right, $mint-light, $green);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-mint-accent {
  background-image: linear-gradient(to right, $mint-light, $accent);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-mint-purple {
  background-image: linear-gradient(to right, $mint, $purple);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-mint-accent-10 {
  background-image: linear-gradient(90deg, $mint-light 10%, $accent 110%) !important;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-mint-accentdarker-20 {
  background-image: linear-gradient(90deg, $mint-light 20%, $accent-darker 120%) !important;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-accent-mint {
  background-image: linear-gradient(to right, $accent, $mint-light);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-mint-primary {
  background-image: linear-gradient(to right, $mint-light, $primary);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-mintdarker-primary {
  background-image: linear-gradient(to right, $mint, $primary);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-mint-indigo {
  background-image: linear-gradient(to right, $mint-darker, $indigo);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-mint-warn {
  background-image: linear-gradient(to right, $mint, $warn);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-mint-warndarker {
  background-image: linear-gradient(to right, $mint, $warn-darker);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-accent-warn {
  background-image: linear-gradient(to right, $accent, $warn);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.c-gr-accent-warn-light {
  background-image: linear-gradient(to right, $accent, $warn 130%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.c-gr-mint-pink {
  background-image: linear-gradient(to right, $mint, $pink);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-primary-pink {
  background-image: linear-gradient(to right, $primary, $pink);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-mintlight-warnlight {
  background-image: linear-gradient(90deg, $mint-light 10%, $warn 130%) !important;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-mintlight-pink-10 {
  background-image: linear-gradient(90deg, $mint-light 10%, $pink 110%) !important;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.c-gr-pink-accent {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, $pink 10%, $accent 120%) !important;
}

.c-gr-pink-amber {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, $pink 10%, $amber 120%) !important;
}

.c-gr-pink-primary-10 {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, $pink 10%, $primary 90%) !important;
}

.c-gr-green-pink {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, $green 10%, $pink 120%) !important;
}

.c-gr-warnlight-green {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, $warn-light 10%, $green 120%) !important;
}

.c-gr-mint-green {
  //background-image: linear-gradient(to right, $mint, $green-darker);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, $mint-light 10%, $green 120%) !important;
}

.c-gr-mint-green-10 {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, $mint-light 10%, $green-darker 110%) !important;
}

.c-gr-mint-pink-accent {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, $mint-light 20%, $pink 60%, $accent 90%) !important;
}

.c-gr-mint-accent-pink {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, $mint-light 20%, $accent 40%, $pink 90%) !important;
}

.c-gr-accent-primary-pink {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, $accent -10%, $primary 50%, $pink 90%) !important;
}

.c-gr-mint-pink-primary {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, $mint-light 20%, $pink 60%, $primary 100%) !important;
}

.bg-mint-darker {
  background-color: $mint-darker !important;
  color: white !important;
}

.bg-mint {
  background-color: $mint !important;
  color: white !important;
}

.c-mint-darker {
  color: $mint-darker !important;
}

.c-green-light {
  color: $green-light !important;
}

.c-green {
  color: $green !important;
}

.c-green-darker {
  color: $green-darker !important;
}

.bg-green {
  background-color: $green !important;
}

.bg-green-darker {
  background-color: $green-darker !important;
}

.bg-green-light {
  background-color: $green-light !important;
}

.c-warn-darker {
  color: $warn-darker !important;
}

.c-warn {
  color: $warn !important;
}

.bg-amber {
  background-color: $amber !important;
}

.bg-amber-light {
  background-color: $amber-light;
}

.bg-amber-darker {
  background-color: $amber-darker !important;
}

.c-amber {
  color: $amber !important;
}
.bg-orange {
  background-color: $orange !important;
}

.bg-orange-light {
  background-color: $orange-light;
}

.bg-orange-darker {
  background-color: $orange-darker !important;
}
.c-orange {
  color: $orange;
}
.c-orange-light {
  color: $orange-light;
}
.c-orange-darker {
  color: $orange-darker;
}

//pantone color
.bg-p-coralparadise {
  background-color: #f05e73 !important;
}

.bg-p-flamingoplume {
  background-color: #de838d !important;
}

.bg-p-murexshell {
  background-color: #f8a3a4 !important;
}

.bg-p-blushingrose {
  background-color: #f2c1ac !important;
}

.bg-p-paledogwood {
  background-color: #efd1c6 !important;
}

.bg-p-graysand {
  background-color: #e5ccaf !important;
}

.bg-p-graylilac {
  background-color: #d4cacd !important;
}

.bg-p-agategray {
  background-color: #b1b09f !important;
}

.bg-p-pleinair {
  background-color: #bfcad6 !important;
}


.bg-p-09-mimosa {
  background-color: #f0c05a !important;
}

.bg-p-10-turquoise {
  background-color: #45b5aa !important;
}

.bg-p-11-honeysuckle {
  background-color: #d85a7b !important;
}

.bg-p-12-tangerinetango {
  background-color: #dd4124 !important;
}

.bg-p-13-emerald {
  background-color: #00997b !important;
}

.bg-p-14-radiantorchid {
  background-color: #ad5e99 !important;
}

.bg-p-15-marsala {
  background-color: #964f4c !important;
}

.bg-p-16-quartz {
  background-color: #f7caca !important;
}

.bg-p-16-serenity {
  background-color: #93a9d1 !important;
}

.bg-p-17-greenery {
  background-color: #88b04b !important;
}

.bg-p-18-ultraviolet {
  background-color: #5f4b8b !important;
}

.bg-p-19-livingcoral {
  background-color: #ff6f61 !important;
}

.c-p-19-livingcoral {
  color: #ff6f61 !important;
}

.bg-p-20-classicblue {
  background-color: #0f4c81 !important;
}

.bg-p-21-llluminatiing {
  background-color: #f5df4d !important;
}

.bg-p-22-veryperi {
  background-color: #6667ab !important;
}

.bg-p-23-vivamagent {
  background-color: #e24666 !important;
}

.c-p-23-vivamagent {
  color: #e24666 !important;
}

.bg-white-blur {
  background: rgba(255, 255, 255, 0.1) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2) !important;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.bg-white-blur-down {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.bg-primary-blur {
  background: rgba(101, 78, 124, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  //border: 1px solid rgba(255, 255, 255, 0.1);
}

.bg-primary-blur-xs {
  background: rgba(101, 78, 124, 0.2);
}

.bg-warn-blur {
  background: rgba(244, 106, 112, 0.3);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  //border: 1px solid rgba(255, 255, 255, 0.1);
}

.bg-accent-blur {
  background: rgba(19, 125, 197, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  //border: 1px solid rgba(19, 125, 197, 0.1);
}
.bg-gray-blur {
  background: rgba(153, 153, 153, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  //border: 1px solid rgba(19, 125, 197, 0.1);
}
.bg-mint-blur {
  background: rgba(20, 178, 174, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  //border: 1px solid rgba(20, 178, 174, 0.1);
}

.bg-green-blur {
  background: rgba(15, 165, 100, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  //border: 1px solid rgba(255, 255, 255, 0.1);
}

.bg-green-blur-xs {
  background: rgba(15, 165, 100, 0.2);
}

.bg-amber-blur {
  background: rgba(255, 213, 79, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  //border: 1px solid rgba(255, 213, 79, 0.1);
}

.bg-white-shadow {
  background: #ffffff !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid inherit !important;
}

.bg-blue-light {
  background: #F8FCFF;
}

.bg-sheet {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.bg-gn-light {
  background: #E6F6EFFF !important;
}

.bg-empty {
  background-color: #FFFFFF !important;
  border-radius: 20px !important;
}

.bg-gr-primarylight-white-180 {
  background-color: $primary-light;
  background-image: linear-gradient(180deg, $primary-light 0%, #ffffff 100%) !important;
}

.bg-gr-primarylight-white-180-xs {
  background-color: $primary-light;
  background-image: linear-gradient(180deg, $primary-light 40%, #ffffff 100%) !important;
}

.bg-gr-graylight-white-180 {
  background-color: $gray-light;
  background-image: linear-gradient(180deg, $gray-light 0%, #ffffff 100%) !important;
}

.bg-gr-da-white-180 {
  background-color: #ffffff;
  background-image: linear-gradient(180deg, #dadada 0%, #ffffff 100%) !important;
}

.bg-gr-da-white-180-xs {
  background-color: #ffffff;
  background-image: linear-gradient(180deg, #dadada 40%, #ffffff 100%) !important;
}

.bg-gr-pink-accent-180 {
  background-color: $pink-light;
  background-image: linear-gradient(180deg, $pink-light 0%, $accent 50%) !important;
}

.bg-gr-accent-white-180 {
  background-color: $accent;
  background-image: linear-gradient(180deg, $accent 0%, #ffffff 100%) !important;
}
.bg-gr-accentlight-white-180 {
  background-color: $accent-light;
  background-image: linear-gradient(180deg, $accent-light 0%, #ffffff 100%) !important;
}

.bg-gr-mint-white {
  background-color: $mint;
  background-image: linear-gradient(90deg, #40bfc0 0%, #ffffff 100%) !important;
}
.bg-gr-mint-indigo {
  background-color: $mint;
  background-image: linear-gradient(90deg, $mint 0%, $indigo 100%) !important;
  color: white;
}
.bg-gr-accent-white {
  background-color: $accent;
  background-image: linear-gradient(90deg, $accent 0%, #ffffff 100%) !important;
}

.bg-gr-primary-white {
  background-color: $primary;
  background-image: linear-gradient(90deg, $primary -30%, #ffffff 100%) !important;
}

.bg-gr-primary-white-m60 {
  background-color: $primary;
  background-image: linear-gradient(90deg, $primary -60%, #ffffff 100%) !important;
}

.bg-gr-white-primary {
  background-color: #ffffff;
  background-image: linear-gradient(90deg, #ffffff 0%, $primary 200%) !important;
}

.bg-gr-primary-180 {
  background-color: $primary;
  background-image: linear-gradient(180deg, $primary 15%, #ffffff 100%) !important;
}

.bg-gr-primary-mint {
  background-color: $primary !important;
  background-image: linear-gradient(90deg, $primary 20%, $mint 100%) !important;
}

.bg-gr-primary-green {
  background-color: $primary !important;
  background-image: linear-gradient(90deg, $primary 20%, $green 100%) !important;
}

.bg-gr-primary-mint-m10 {
  background-color: $primary !important;
  background-image: linear-gradient(90deg, $primary -10%, $mint 110%) !important;
}

.bg-gr-primary-accent {
  background-color: $primary !important;
  background-image: linear-gradient(90deg, $primary 20%, $accent 100%) !important;
}

.bg-gr-mint-primary-m10 {
  background-color: $mint !important;
  background-image: linear-gradient(90deg, $mint -10%, $primary 100%) !important;
}

.bg-gr-mint-purple {
  background-color: $mint !important;
  background-image: linear-gradient(90deg, $mint 0%, $purple 100%) !important;
  color: white;
}

.bg-gr-mintlight-primary-10 {
  background-color: $mint-light !important;
  background-image: linear-gradient(90deg, $mint-light 10%, $primary 110%) !important;
  color: white;
}

.bg-gr-mintlight-primary-20 {
  background-color: $mint-light !important;
  background-image: linear-gradient(90deg, $mint-light 20%, $primary 120%) !important;
  color: white;
}

.bg-gr-mint-primary-y {
  background-color: $mint !important;
  background-image: linear-gradient(180deg, $mint 0%, $primary 100%) !important;
  color: white;
}

.bg-gr-mint-pink-y {
  background-color: $mint !important;
  background-image: linear-gradient(180deg, $mint 0%, $pink 100%) !important;
  color: white;
}
.bg-gr-mint-accent-y {
  background-color: $mint !important;
  background-image: linear-gradient(180deg, $mint 0%, $accent 100%) !important;
  color: white;
}
.bg-gr-mint-accent {
  background-color: $mint !important;
  background-image: linear-gradient(90deg, $mint 0%, $accent 100%) !important;
  color: white;
}
.bg-gr-mint-accent-darker {
  background-color: $mint !important;
  background-image: linear-gradient(90deg, $mint 0%, $accent-darker 100%) !important;
  color: white;
}
.bg-gr-accent-purple {
  background-color: $accent !important;
  background-image: linear-gradient(90deg, $accent 0%, $purple 100%) !important;
  color: white;
}
.bg-gr-accent-purple-light {
  background-color: $accent !important;
  background-image: linear-gradient(90deg, $accent 0%, $purple 130%) !important;
  color: white;
}
.bg-gr-accent-primary {
  background-color: $accent !important;
  background-image: linear-gradient(90deg, $accent 0%, $primary 100%) !important;
  color: white;
}

.bg-gr-accent-warn {
  background-color: $accent !important;
  background-image: linear-gradient(90deg, $accent 0%, $warn 100%) !important;
  color: white;
}

.bg-gr-accent-pink {
  background-color: $accent !important;
  background-image: linear-gradient(90deg, $accent 0%, $pink 100%) !important;
  color: white;
}
.bg-gr-accent-pink-light {
  background-color: $accent !important;
  background-image: linear-gradient(90deg, $accent 0%, $pink 130%) !important;
  color: white;
}
.bg-gr-accent-accentdarker {
  background-color: $accent !important;
  background-image: linear-gradient(90deg, $accent 0%, $accent-darker 100%) !important;
  color: white;
}

.bg-gr-warn-accent {
  background-color: $warn !important;
  background-image: linear-gradient(90deg, $warn 0%, $accent 100%) !important;
  color: white;
}

.bg-gr-pink-accent {
  background-color: $pink !important;
  background-image: linear-gradient(90deg, $pink 0%, $accent 100%) !important;
  color: white;
}

.bg-gr-pink-amber {
  background-color: $pink !important;
  background-image: linear-gradient(90deg, $pink 0%, $amber 100%) !important;
  color: white;
}

.bg-gr-pink-green {
  background-color: $pink !important;
  background-image: linear-gradient(90deg, $pink 0%, $green 100%) !important;
  color: white;
}

.bg-gr-pink-primary {
  background-color: $pink !important;
  background-image: linear-gradient(90deg, $pink 0%, $primary 100%) !important;
  color: white;
}

.bg-gr-pink-primary-30 {
  background-color: $pink !important;
  background-image: linear-gradient(90deg, $pink 30%, $primary 130%) !important;
  color: white;
}

.bg-gr-pink-mint {
  background-color: $pink !important;
  background-image: linear-gradient(90deg, $pink 0%, $mint 100%) !important;
  color: white;
}

.bg-gr-purple-amber {
  background-color: $purple;
  background-image: linear-gradient(90deg, $purple 0%, $amber 100%) !important;
  color: white;
}

.bg-gr-green-amber {
  background-color: $green;
  background-image: linear-gradient(90deg, $green 0%, $amber 100%) !important;
  color: white;
}

.bg-gr-green-warn {
  background-color: $green;
  background-image: linear-gradient(90deg, $green 0%, $warn 100%) !important;
  color: white;
}

.bg-gr-green-pink {
  background-color: $green;
  background-image: linear-gradient(90deg, $green 0%, $pink 100%) !important;
  color: white;
}

.bg-gr-gray-amber {
  background-color: $gray-deep;
  background-image: linear-gradient(90deg, $gray-deep 0%, $amber 100%) !important;
  color: white;
}

.bg-gr-mint-green {
  background-color: $mint-darker;
  background-image: linear-gradient(90deg, $mint-darker 0%, $green 100%) !important;
  color: white !important;
}

.bg-gr-mint-accent {
  background-color: $mint;
  background-image: linear-gradient(90deg, $mint 0%, $accent 100%) !important;
  color: white !important;
}
.bg-gr-accent-mint {
  background-color: $accent;
  background-image: linear-gradient(90deg, $accent 0%, $mint 100%) !important;
  color: white !important;
}

.bg-gr-mintdark-purple {
  background-color: $mint-darker;
  background-image: linear-gradient(90deg, $mint-darker 0%, $purple 100%) !important;
  color: white !important;
}

.bg-gr-mint-primary {
  background-color: $mint;
  background-image: linear-gradient(90deg, $mint 0%, $primary 100%) !important;
  color: white !important;
}

.bg-gr-mint-purple {
  background-color: $mint;
  background-image: linear-gradient(90deg, $mint 10%, $purple 120%) !important;
  color: white !important;
}

.bg-gr-mint-warn {
  background-color: $mint;
  background-image: linear-gradient(90deg, $mint 0%, $warn 100%) !important;
  color: white !important;
}

.bg-gr-mintlight-warnlight {
  background-color: $mint;
  background-image: linear-gradient(90deg, $mint-light 30%, $warn 130%) !important;
  color: white !important;
}

.bg-gr-mint-pink {
  background-color: $mint;
  background-image: linear-gradient(90deg, $mint 0%, $pink 100%) !important;
  color: white !important;
}

.bg-gr-mint-pink-20 {
  background-color: $mint;
  background-image: linear-gradient(90deg, $mint 20%, $pink 120%) !important;
  color: white !important;
}

.bg-gr-mintlight-pink {
  background-color: $mint-light;
  background-image: linear-gradient(90deg, $mint-light 0%, $pink 100%) !important;
  color: white !important;
}

.bg-gr-mintlight-pink-20 {
  background-color: $mint-light;
  background-image: linear-gradient(90deg, $mint-light 20%, $pink 120%) !important;
  color: white !important;
}

.bg-gr-mintlight-pink-10-110 {
  background-color: $mint-light;
  background-image: linear-gradient(90deg, $mint-light 10%, $pink 110%) !important;
  color: white !important;
}

.bg-gr-mintlight-accent-10-110 {
  background-color: $mint-light;
  background-image: linear-gradient(90deg, $mint-light 10%, $accent 110%) !important;
  color: white !important;
}

.bg-gr-mintlight-accent-10-150 {
  background-color: $mint-light;
  background-image: linear-gradient(90deg, $mint-light 10%, $accent 150%) !important;
  color: white !important;
}
.bg-gr-light {
  background-color: $accent-light;
  background-image: linear-gradient(90deg, $accent-light 0%, $mint-light 100%) !important;
  color: white !important;
}
.bg-gr-mint-pink-10-130 {
  background-color: $mint;
  background-image: linear-gradient(150deg, $mint -10%, $pink 130%) !important;
  color: white !important;
}

.bg-gr-mintlight-pink-150 {
  background-color: $mint-light;
  background-image: linear-gradient(150deg, $mint-light -0%, $pink 100%) !important;
  color: white !important;
}

.bg-gr-mintdarker-pink {
  background-color: $mint-darker;
  background-image: linear-gradient(90deg, $mint-darker 0%, $pink 100%) !important;
  color: white !important;
}

.bg-gr-mintdarker-amberdarker {
  background-color: $mint-darker;
  background-image: linear-gradient(90deg, $mint-darker 0%, $amber-darker 100%) !important;
  color: white !important;
}

.bg-gr-warn-amber {
  background-color: $warn;
  background-image: linear-gradient(90deg, $warn 0%, $amber-darker 100%) !important;
  color: white;
}

.bg-gr-primary-pink {
  background-color: $primary;
  background-image: linear-gradient(90deg, $primary 0%, $pink 100%) !important;
  color: white;
}

.bg-gr-primary-purple-green {
  background-color: $primary;
  background-image: linear-gradient(90deg, $primary 0%, $purple 50%, $green 100%) !important;
}

.bg-gr-white-pink-primary-145 {
  background-color: $primary;
  background-image: linear-gradient(145deg, #ffffff 30%, $pink 80%, $primary 110%) !important;
}

.opac-5 {
  opacity: .5 !important;
}

.bg-button-gra {
  background-color: $primary !important;
  background-image: linear-gradient(90deg, $mint 0%, $primary 100%) !important;

}

.bg-brown-light {
  background-color: $brown-light !important;
}

.bg-brown {
  background-color: $brown !important;
}

.bg-brown-darker {
  background-color: $brown-darker !important;
}

.c-brown {
  color: $brown !important;
}

.c-brown-darker {
  color: $brown-darker !important;

}

.bg-purple {
  background-color: $purple !important;
  color: white;
}

.bg-purple-darker {
  background-color: $purple-darker !important;
  color: white;
}

.bg-purple-light {
  background-color: $purple-light !important;
}

.bg-indigo {
  background-color: $indigo !important;
  color: white;
}

.bg-indigo-darker {
  background-color: $indigo-darker !important;
  color: white;
}

.bg-indigo-light {
  background-color: $indigo-light !important;
}

.c-indigo {
  color: $indigo;
}

.c-indigo-darker {
  color: $indigo-darker;
}

.bg-neon-pink {
  background-color: $pink;
  padding: 8px 14px 5px 14px !important;
  border-radius: 20px !important;
  color: white !important;
}

.bg-neon-purple {
  background-color: $purple;
  padding: 8px 14px 5px 14px !important;
  border-radius: 20px !important;
  color: white !important;
}

.bg-neon-indigo {
  background-color: $indigo;
  padding: 8px 14px 5px 14px !important;
  border-radius: 20px !important;
  color: white !important;
}

.bg-neon-primary {
  background-color: $primary;
  padding: 8px 14px 5px 14px !important;
  border-radius: 20px !important;
  color: white !important;
}

.bg-neon-accent {
  background-color: $accent;
  padding: 8px 14px 5px 14px !important;
  border-radius: 20px !important;
  color: white !important;
}

.bg-neon-green {
  background-color: $green;
  padding: 8px 14px 5px 14px !important;
  border-radius: 20px !important;
  color: white !important;
}

.bg-neon-brown {
  background-color: $brown ;
  padding: 8px 14px 5px 14px !important;
  border-radius: 20px !important;
  color: white !important;
}

.bg-neon-amber {
  background-color: $amber !important;
  padding: 8px 14px 5px 14px !important;
  border-radius: 20px !important;
  color: #212121 !important;
}

.bg-neon-mint {
  background-color: $mint !important;
  padding: 8px 14px 5px 14px !important;
  border-radius: 20px !important;
  color: #212121 !important;
}

.bg-dialog {
  background-color: #fffbff !important;
}

.main-top-wave {
  background-image: url('/assets/img/gif/wave-gray-light.svg');
  background-size: cover;
  //background-position: top center;
  background-position-y: 40%;
  background-position-x: 90%;
}

.main-top-wave-xs {
  background-image: url('/assets/img/gif/wave-gray-light.svg');
  background-size: cover;
  background-position-y: 30%;
  background-position-x: 65%;
}

.main-top-wave-mint-xs {
  background-image: url('/assets/img/gif/wave-mint.svg');
  background-size: cover;
  background-position: center top;
}

.main-top-wave-accent-xs {
  background-image: url('/assets/img/gif/wave-accent.svg');
  background-size: cover;
  background-position: center top;
}

.main-top-wave-green-xs {
  background-image: url('/assets/img/gif/wave-green.svg');
  background-size: cover;
  background-position: center top;
}

.analy-top-wave {
  background-image: url('/assets/img/gif/wave-mint-warn.svg');
  background-size: cover;
  background-position: center center;
}

.analy-top-wave-xs {
  background-image: url('/assets/img/gif/wave-mint-warn.svg');
  background-size: cover;
  background-position: left center;
}

.analy-bottom-wave-xs {
  background-image: url('/assets/img/gif/wave-mint-warn.svg');
  background-size: cover;
  background-position: left top;
}

.myhouse-top-wave {
  background-image: url('/assets/img/gif/wave-gray-light.svg');
  background-size: cover;
  background-position-y: 40%;
  background-position-x: 70%;
}

.myhouse-top-wave-xs {
  background-image: url('/assets/img/gif/wave-gray-light.svg');
  background-size: cover;
  background-position: top center;
}

.openhouse-top-wave {
  background-image: url('/assets/img/gif/wave-mint-accent.svg');
  background-size: cover;
  background-position: center left;
}

.openhouse-top-wave-xs {
  background-image: url('/assets/img/gif/wave-mint-accent.svg');
  background-size: cover;
  background-position: top center;
}

.company-top-wave {
  background-image: url('/assets/img/gif/wave-mint.svg');
  background-size: cover;
  //background-position: center center;
  background-position-y: 70%;
  background-position-x: 90%;
}

.company-top-wave-xs {
  background-image: url('/assets/img/gif/wave-mint-green.svg');
  background-size: cover;
  background-position: left center;
}

.analy-select-wave {
  background-image: url('/assets/img/gif/wave-primary-mint.svg');
  background-size: cover;
  background-position: center left;
}

.analy-select-wave-xs {
  background-image: url('/assets/img/gif/wave-primary-mint.svg');
  background-size: cover;
  background-position: center left;
}

.myhome-wave {
  background-image: url('/assets/img/gif/wave-mint-primary.svg');
  background-size: cover;
  //background-position: center center;
  background-position-y: 90%;
  background-position-x: 50%;
}

.myhome-wave-xs {
  background-image: url('/assets/img/gif/wave-purple-light.svg');
  background-size: cover;
  background-position: center center;
}


.grade-amber {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 7px 3px 7px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $amber-darker;
}

.grade-amber-xs {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 4px 3px 4px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $amber-darker;
}

.grade-amber-info {
  width: 10px;
  height: 5px;
  padding: 1px 10px;
  border-radius: 50px;
  margin-right: 3px;
  background-color: $amber-darker;
}

.grade-orange {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 7px 3px 7px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $orange;
}

.grade-amber-xs {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 4px 3px 4px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $orange;
}

.grade-amber-info {
  width: 10px;
  height: 5px;
  padding: 1px 10px;
  border-radius: 50px;
  margin-right: 3px;
  background-color: $orange;
}


.grade-mint {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 7px 3px 7px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $mint;
}

.grade-mint-xs {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 4px 3px 4px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $mint;
}

.grade-mint-info {
  width: 10px;
  height: 5px;
  padding: 1px 10px;
  border-radius: 50px;
  margin-right: 3px;
  background-color: $mint;
}

.grade-primary {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 7px 3px 7px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $primary;
}

.grade-primary-xs {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 4px 3px 4px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $primary;
}

.grade-primary-info {
  width: 10px;
  height: 5px;
  padding: 1px 10px;
  border-radius: 50px;
  margin-right: 3px;
  background-color: $primary;
}

.grade-pink {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 7px 3px 7px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $pink;
}

.grade-pink-xs {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 4px 3px 4px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $pink;
}

.grade-pink-info {
  width: 10px;
  height: 5px;
  padding: 1px 10px;
  border-radius: 50px;
  margin-right: 3px;
  background-color: $pink;
}

.grade-warn {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 7px 3px 7px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $warn;
}

.grade-warn-xs {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 4px 3px 4px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $warn;
}

.grade-warn-info {
  width: 10px;
  height: 5px;
  padding: 1px 10px;
  border-radius: 50px;
  margin-right: 3px;
  background-color: $warn;
}

.grade-warn-darker {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 7px 3px 7px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $warn-darker;
}

.grade-warn-darker-xs {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 4px 3px 4px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $warn-darker;
}

.grade-warn-darker-info {
  width: 10px;
  height: 5px;
  padding: 1px 10px;
  border-radius: 50px;
  margin-right: 3px;
  background-color: $warn-darker;
}

.grade-gray {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 7px 3px 7px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $gray;
}

.grade-gray-xs {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 4px 3px 4px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $gray;
}

.grade-gray-info {
  width: 10px;
  height: 5px;
  padding: 1px 10px;
  border-radius: 50px;
  margin-right: 3px;
  background-color: $gray;
}

.grade-accent {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 7px 3px 7px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $accent;
}
.grade-accent-xs {
  width: 10px;
  height: 10px;
  font-size: 11px;
  padding: 1px 4px 3px 4px;
  border-radius: 50px;
  font-weight: 600;
  color: white;
  text-align: center;
  margin-right: 2px;
  background-color: $accent;
}

.grade-accent-info {
  width: 10px;
  height: 5px;
  padding: 1px 10px;
  border-radius: 50px;
  margin-right: 3px;
  background-color: $accent;
}

.c-accent-sky {
color: rgba(19, 125, 197, 0.4);
}
.bg-accent-sky {
  background-color: rgba(19, 125, 197, 0.4);
}
