@import './variables';
section {
  padding: 25px 0;
  max-width: 1200px;
  min-height: calc(100vh - 200px);
  left: 0;
  right: 0;
  margin: auto;
}

@media screen and (max-width: 959px) {
  section {
    padding: 25px 0;
    min-height: calc(100vh - 225px);
  }
}

.super-section {
  max-width: 1800px;
}

.narrow-section {
  max-width: 1160px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 25px 0;
}

@media screen and (max-width: 959px) {
  .narrow-section {
    max-width: 1000px;
    left: 0;
    right: 0;
    margin: auto;
    //background-color: #ffffff;
    padding: 15px 0 !important;
  }
}

.wide-section {
  max-width: 1300px;
  left: 0;
  right: 0;
  margin: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

@media screen and (max-width: 959px) {
  .wide-section {
    left: 0;
    right: 0;
    margin: auto;
    //background-color: #ffffff;
    padding: 10px 0 !important;
  }
}

.main-visual-section {
  max-width: 1400px;
  left: 0;
  right: 0;
  margin: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

@media screen and (max-width: 959px) {
  .main-visual-section {
    left: 0;
    right: 0;
    margin: auto;
    //background-color: #ffffff;
    padding: 10px 0 !important;
  }
}

.wide-full-section {
  max-width: 1920px;
  left: 0;
  right: 0;
  margin: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

@media screen and (max-width: 959px) {
  .wide-full-section {
    left: 0;
    right: 0;
    margin: auto;
    padding: 10px 0 !important;
  }
}

.none-padding-section {
  max-width: 900px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0 !important;
}

.min-h-60 {
  min-height: 60px !important;
}

.min-h-10 {
  min-height: 10px !important;
}

.header {
  background: #fff;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  //box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .12), inset 0 0 0 0 #dadce0;
}

.of-auto {
  overflow: auto !important;
}

.p-v-0 {
  padding-top: 0!important;
  padding-bottom: 0!important;
}

.align-clean{
  display: flex;
  align-items: center;
}
