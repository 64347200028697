@import "./variables";

// Text Align
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-start {
  text-align: start !important;
}

.va-middle {
  vertical-align: middle !important;
}

.align-top {
  vertical-align: top;
}

.whitespace-pre-line {
  white-space: pre-line;
}

// Text Color
.c-primary-light {
  color: $primary-light !important;
}

.c-primary {
  color: $primary !important;
}

.c-primary-darker {
  color: $primary-darker !important;
}

.c-accent-light {
  color: $accent-light;
}

.c-accent {
  color: $accent!important;
}

.c-accent-darker {
  color: $accent !important;
}

.c-warn-light {
  color: $warn-light !important;
}

.c-warn {
  color: $warn !important;
}

.c-gray-light {
  color: $gray-light !important;
}

.c-gray {
  color: $gray !important;
}

.c-gray-deep {
  color: $gray-deep !important;
}

.c-gray-dark {
  color: $gray-dark !important;
}

.c-gray-ca {
  color: #cacaca !important;
}

.c-gray-da {
  color: #dadada !important;
}

.c-gray-ea {
  color: #eaeaea !important;
}

.c-white {
  color: white !important;
}

.c-mint {
  color: $mint !important;
}

.c-mint-darker {
  color: $mint-darker !important;
}

.c-black {
  color: #212121 !important;
}

.c-dark {
  color: #000000;
}

.c-gn-light {
  color: #e7f2ee !important;
}


// Text LineHeight
.lh-0 {
  line-height: 0 !important;
}

.lh-1-2 {
  line-height: 1.2 !important;
}

.lh-1-3 {
  line-height: 1.3 !important;
}

.lh-1-4 {
  line-height: 1.4 !important;
}

.lh-1-5 {
  line-height: 1.5 !important;
}

.lh-1-6 {
  line-height: 1.6 !important;
}

.lh-1-7 {
  line-height: 1.7 !important;
}

.lh-1-8 {
  line-height: 1.8 !important;
}

.lh-1-9 {
  line-height: 1.9 !important;
}

.lh-2 {
  line-height: 2 !important;
}

.lh-3-5 {
  line-height: 3.5 !important;
}

.lh-4 {
  line-height: 4 !important;
}

.lh-29px {
  line-height: 29px !important;
}

.lh-38px {
  line-height: 38px !important;
}

// Text Circle
.number-circle {
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background: #dadada;
  color: #555555;
  text-align: center;
  font-size: 12px;
}

.number-circle-subhead {
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 50%;
  display: inline-block;
  background: $primary-light;
  color: white;
  text-align: center;
  font-size: 12px;
  margin: 0 7px 10px 0;
}

.text-circle {
  display: inline-block;
  min-width: 50px;
  min-height: 50px;
  line-height: 50px !important;
  border-radius: 50%;
  text-align: center;
}

.text-circle-mini {
  display: inline-block;
  min-width: 50px;
  min-height: 50px;
  line-height: 50px !important;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
}

.text-circle-40 {
  display: inline-block;
  min-width: 40px;
  min-height: 40px;
  line-height: 40px !important;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.text-circle-35 {
  display: inline-block;
  min-width: 35px;
  min-height: 35px;
  line-height: 35px !important;
  border-radius: 50%;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
}

.text-circle-32 {
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  text-align: center;
}

.text-circle-30 {
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  text-align: center;
}

.text-circle-20 {
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  text-align: center;
}

.text-circle-60 {
  display: inline-block;
  min-width: 60px;
  min-height: 60px;
  line-height: 60px !important;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
}

.text-circle-70 {
  display: inline-block;
  min-width: 70px;
  min-height: 70px;
  line-height: 70px !important;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
}

// Text Badge
.text-badge {
  width: fit-content;
  border: 1px solid $gray-light;
  border-radius: 15px;
  padding: 3px 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.text-badge-gray {
  width: fit-content;
  border: 1px solid $gray;
  border-radius: 15px;
  padding: 3px 8px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.text-badge-warn-s {
  width: fit-content;
  border: 1px solid $warn;
  border-radius: 20px;
  padding: 3px 10px 5px 10px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 12px !important;
  color: $warn;
}
.text-badge-warn-fill {
  width: fit-content;
  background-color: $gray-deep;
  border-radius: 20px;
  padding: 4px 10px 6px 10px !important;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 12px !important;
  color: white;
}
.notice-badge {
  width: fit-content;
  border-radius: 20px;
  padding: 4px 10px 6px 10px !important;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 12px !important;
  color: #ffffff;
  background-color: $warn !important;
}

.text-badge-white {
  width: fit-content;
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 15px;
  padding: 3px 8px !important;
  font-size: 12px;
  line-height: 12px !important;
  font-weight: 500;
}

.badge {
  background-color: $accent;
  border-radius: 15px;
  padding: 4px 10px;
  font-size: 12px !important;
  line-height: 13px !important;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
}

.badge-title {
  background-color: black;
  color: #ffffff;
  border-radius: 15px;
  padding: 3px 10px 4px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.badge-title-primary {
  background-color: $primary;
  color: #ffffff;
  border-radius: 15px;
  padding: 3px 10px 4px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.badge-title-gray {
  background-color: $gray;
  color: #ffffff;
  border-radius: 15px;
  padding: 3px 10px 4px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.badge-tab-primary {
  background-color: $primary;
  color: #ffffff;
  border-radius: 15px;
  padding: 4px 7px 3px 7px;
  font-size: 11px;
  line-height: 11px;
  font-weight: 500;
}

.badge-tab-gray {
  background-color: $gray;
  color: #ffffff;
  border-radius: 15px;
  padding: 4px 7px 3px 7px;
  font-size: 11px;
  line-height: 11px;
  font-weight: 500;
}
.badge-kr {
  margin-right: 5px;
  padding: 3px 10px 5px 10px !important;
  border-radius: 20px;
  font-size: 12px !important;
  line-height: 13px !important;
  font-weight: 500;
  text-align: center;
}

.badge-kr-md {
  min-width: 30px;
  padding: 6px 10px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
}
.badge-kr-xs {
  min-width: 30px;
  padding: 2px 10px;
  border-radius: 20px;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
}

// ETC
.number {
  font-size: 14px;
  letter-spacing: 1px;
}

.text-link {
  text-decoration: underline;
  color: black;
  cursor: pointer;
  font-weight: 600;
}

.text-pre {
  white-space: pre-line;
  line-height: 1.7;
}

.text-loss-100 {
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-loss-57 {
  width: 57px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.img-revert {
  transform: scaleX(-1);
}
.c-purple-darker {
  color: $purple-darker !important;
}
.c-purple {
  color: $purple !important;
}
.c-purple-light {
  color: $purple-light !important;
}

//text type
.mat-headline-1 {
  font-size: 112px;
  margin-bottom: 112px;
  font-weight: 700;
  //font-family: 'S-CoreDream-6Bold', serif;
}

.mat-headline-2 {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px;
  letter-spacing: -2.5px;
  //font-family: 'S-CoreDream-6Bold', serif;
}

.mat-headline-3 {
  font-size: 45px;
  line-height: 1.6;
  margin-bottom: 45px;
  font-weight: 700;
  //font-family: 'S-CoreDream-6Bold', serif;
}

.mat-headline-4 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.26px;
  //font-family: 'S-CoreDream-6Bold', serif;
}
.mat-headline-5 {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: -0.7px;
  //font-family: 'S-CoreDream-6Bold', serif;
}
.mat-headline-6 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.5px;
  //font-family: 'S-CoreDream-6Bold', serif;
}

.mat-subtitle-1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.26px;
}

.mat-subtitle-2 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.36px;
}

.mat-subtitle-3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.26px;
  //font-family: 'S-CoreDream-6Bold', serif;
}

.mat-body-1 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 1.56;
}

.mat-body-2 {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 1.7;
}

.mat-caption {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: 1.56;
}

.mat-button {
  font-size: 15px;
  font-weight: 500;
  //font-family: 'S-CoreDream-6Bold', serif;
}

.c-gray-85 {
  color: #858585!important;
}
.c-gray-2B {
  color: #2B2B2B!important;
}
.c-pink {
  color: $pink;
}
.c-pink-darker {
  color: $pink-darker;
}
.c-pink-light {
  color: $pink-light;
}
.c-baba {
  color: #BABABA;
}
.title-favorite {
  color: #fff;
  text-shadow: 2px 4px 12px #111e4a;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: -0.26px;
}

.title-card-lg {
  color: #1f1f1f;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.3px;
}

.c-tab-on {
  color: #F1F6FF!important;
  font-size: 15px!important;
  font-weight: 500!important;
}
.c-tab-off {
  color: #4B8FF7!important;
  font-size: 15px!important;
  font-weight: 500!important;
}
.c-tab-on-xs {
  color: #F1F6FF!important;
  font-size: 12px!important;
  font-weight: 500!important;
}
.c-tab-off-xs {
  color: #4B8FF7!important;
  font-size: 12px!important;
  font-weight: 500!important;
}

//count-info-color
.count-point{
  color:#1540F4;
}


// Text Weight
$i: 0;
@while ($i <= 900) {
  .f-w-#{$i} {
    font-weight: #{$i} !important;
  }
  $i: $i + 1;
}
