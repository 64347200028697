// Color
$primary-light: #F2F4FD;
$primary: #1C3BC0;
$primary-darker: #052457;

$amber-light: #ffe592;
$amber: #F4D225;
$amber-darker: #ffc302;

$accent-light: #F6F8FE;
$accent: #1C3BC0; 
$accent-darker: #152D93;

$warn-light: #f5c4c8;
$warn: #F31623;
$warn-darker: #cc0813;

$gray: #999999;
$gray-light: #f3f5f7;
$gray-light2: #78ADFF;
$gray-deep: #444444;
$gray-dark: #2b2b2b;

$green-light: #92e3cc;
$green: #0d8d56;
$green-darker: #095e3a;

// others
$brown-light: #ebdedd;
$brown: #734743;
$brown-darker: #5a3835;

$mint-lighter: #c5f0ef;
$mint-light: #b1ebea;
$mint: #33cccc;
//$mint-cool: #49ccdb;
$mint-cool: #5ed2df;
$mint-darker: #2db7b7;

$purple-light: #d2c5d8;
$purple: #9855b4;
$purple-darker: #83459c;

//$indigo-light: #BFACC8;
$indigo-light: #ede9f0;
$indigo: #7b6b9c;
$indigo-darker: #5e4873;

$pink-light: #f0b7d2;
$pink: #F76195;
$pink-darker: #e55084;

$orange-darker: #ff7900;
$orange: #ff8314;
$orange-light: #ffa24e;

$accent-sky: rgba(19, 125, 197, 0.3);
