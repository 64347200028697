@import './variables';

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: 1.25em;
}

.separator:not(:empty)::after {
  margin-left: 1.25em;
}

.border-e2 {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
}

.border-9e {
  border: 1px solid #9e9e9e;
  border-radius: 4px;
}

.border-gray {
  border: 1px solid $gray;
  border-radius: 4px;
}
.border-gray-deep {
  border: 1px solid $gray-deep!important;
}
.border-gray-dark {
  border: 1px solid $gray-dark!important;
}
.border-primary {
  border: 1px solid $primary;
  border-radius: 4px;
}
.border-primary-2 {
  border: 2px solid $primary!important;
  border-radius: 4px;
}
.border-primary-darker-2 {
  border: 2px solid $primary-darker!important;
}
.border-warn {
  border: 1px solid $warn;
  border-radius: 4px;
}

.border-r-black {
  border-right: 1px solid #212121;
}
.border-t {
  border-top: 1px solid $gray-deep;
}

.border-b-gray {
  border-bottom: 1px solid $gray;
}

.border-t-gray {
  border-top: 1px solid $gray!important;
}

.border-title {
  border-bottom: 1px solid $gray-deep;
  padding-bottom: 5px;
}


.border-b-dashed {
  border-bottom: 1px dashed #777777;
  padding-bottom: 5px;
}
.border-b-dashed-gray {
  border-bottom: 1px dashed $gray;
  padding-bottom: 5px;
}
.border-b-dashed-gray {
  border-bottom: 1px dashed #999999;
  padding-bottom: 5px;
}

.border-b-e2 {
  border-bottom: 1px solid #e2e2e2;
}

.border-t-dashed {
  border-top: 1px dashed #777777;
}

.border-none {
  border: none !important;
}

.brd-5 {
  border-radius: 5px!important;
}
.brd-8 {
  border-radius: 8px!important;
}

.brd-10 {
  border-radius: 10px!important;
}
.brd-15 {
  border-radius: 15px!important;
}
.brd-16 {
  border-radius: 16px!important;
}
.brd-20 {
  border-radius: 20px!important;
}
.brd-30 {
  border-radius: 30px!important;
}
.brd-40 {
  border-radius: 40px!important;
}
.brd-50 {
  border-radius: 50px!important;
}
.brd-82 {
  border-radius: 82px!important;
}
.brd-100 {
  border-radius: 100px!important;
}
.brd-150{
  border-radius: 150px!important;
}

.shadow-box {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
}

.shadow-box-xs {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.border-5{
 border:6px solid #052861;
}

.partner-shadow {
  box-shadow: 1px 1px 3.41px 0 rgba(0, 0, 0, 0.24);
  border-radius: 50%;
}
