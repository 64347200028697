$i: 0;
@while ($i <= 400) {

  .m-#{$i} {
    margin: #{$i}px !important;
  }
  .m-l-#{$i} {
    margin-left: #{$i}px !important;
  }
  .m-l-m#{$i} {
    margin-left: -#{$i}px !important;
  }
  .m-t-#{$i} {
    margin-top: #{$i}px !important;
  }
  .m-t-m#{$i} {
    margin-top: -#{$i}px !important;
  }
  .m-r-#{$i} {
    margin-right: #{$i}px !important;
  }
  .m-r-m#{$i} {
    margin-right: -#{$i}px !important;
  }
  .m-b-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .m-b-m#{$i} {
    margin-bottom: -#{$i}px !important;
  }
  .m-h-#{$i} {
    margin-left: #{$i}px !important;
    margin-right: #{$i}px !important;
  }
  .m-v-#{$i} {
    margin-top: #{$i}px !important;
    margin-bottom: #{$i}px !important;
  }
  .p-#{$i} {
    padding: #{$i}px !important;
  }
  .p-t-#{$i} {
    padding-top: #{$i}px !important;
  }
  .p-r-#{$i} {
    padding-right: #{$i}px !important;
  }
  .p-b-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  .p-l-#{$i} {
    padding-left: #{$i}px !important;
  }
  .p-h-#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }
  .p-v-#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }

  .w-#{$i}px {
    width: #{$i}px !important;
  }

  @if ($i < 199) {
    .f-#{$i} {
      font-size: #{$i}px !important;
    }
  }

  $i: $i + 1;
}


@while ($i <= 900) {
  .f-w-#{$i} {
    font-weight: #{$i} !important;
  }
  $i: $i + 1;
}
