@import './variables';

:focus {
  outline: none;
}

.hover:hover {
  background: inherit!important;
  font-weight: 700;
}

.hover-primary:hover {
  background: $primary-darker !important;
  color: #ffffff !important;
}

// .hover-primary-light:hover {
//   background: $primary-light !important;
// }

//mouse-hover, animation
.hover-primary-light:hover {
  transition: background-color 0.5s ease;
  background: $gray-light2 !important;
  color:#000000;
}

.hover-gray-deep:hover {
  background: $gray-deep !important;
  color: #ffffff !important;
}

.hover-dark:hover {
  background: #333333 !important;
  color: #ffffff !important;
}
.hover-gray:hover {
  background: #f2f3f4 !important;
  cursor: pointer;
  //border: 1px solid #f2f3f4;
}

.hover-gray-fa:hover {
  background: #fafafa !important;
  cursor: pointer;
}

.hover-white:hover {
  background: #ffffff !important;
  color: $primary !important;
}

.hover-primary-line:hover {
  background: $primary-darker !important;
  outline: 1px outset $primary-darker !important;
  color: white!important;
}

.hover-shadow:hover {
  box-shadow: 15px 15px 15px #ccc;
}

.hover-gray-shadow:hover {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .12), inset 0 -1px 0 0 #dadce0;
}

.hover-line:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
}
