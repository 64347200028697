@import "../variables";

.swiper-button-next, .swiper-button-prev {
  //display: none !important;
  display: inline;
}

.swiper-pagination-bullet-active {
  width: 7px !important;
  height: 7px !important;
  border-color: transparent !important;
  background: $gray-deep !important;
  //background: #000000 !important;
  //border: 1px solid #000000;
  border: 1px solid $gray-deep;
  opacity: 1 !important;
}

.swiper-button-next, .swiper-button-prev {
  align-items: center;
  color: $primary !important;
  cursor: pointer;
  display: flex;
  height: var(--swiper-navigation-size);
  justify-content: center;
  margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 10) !important;
  z-index: 10;
}

@media screen and (max-width: 599px) {
  .swiper-button-next, .swiper-button-prev {
    color: $gray-light !important;
    opacity: .1 !important;
  }
}
