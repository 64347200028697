html, body {
  min-height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

body {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: -0.02em;
  -ms-overflow-style: none;
  overflow: auto;
  font-family: 'Noto Sans KR', sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;
}
