@font-face {
  font-family: 'yg-jalnan';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-9Black';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-9Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-8Heavy';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-8Heavy.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-7ExtraBold';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-7ExtraBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-6Bold';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-6Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-5Medium';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-5Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-4Regular';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-4Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-3Light';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-2ExtraLight';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-2ExtraLight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-1Thin';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-1Thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.ff-sc-9 {
  font-family: 'S-CoreDream-9Black', serif !important;
}

.ff-sc-8 {
  font-family: 'S-CoreDream-8Heavy', serif !important;
}

.ff-sc-7 {
  font-family: 'S-CoreDream-7ExtraBold', serif !important;
}

.ff-sc-6 {
  font-family: 'S-CoreDream-6Bold', serif !important;
}

.ff-sc-5 {
  font-family: 'S-CoreDream-5Medium', serif !important;
}

.ff-sc-4 {
  font-family: 'S-CoreDream-4Regular', serif !important;
}

.ff-sc-3 {
  font-family: 'S-CoreDream-3Light', serif !important;
}

.ff-sc-2 {
  font-family: 'S-CoreDream-2ExtraLight', serif !important;
}

.ff-sc-1 {
  font-family: 'S-CoreDream-1Thin', serif !important;
}

.material-symbols-outlined {
  vertical-align: middle !important;
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 40px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

