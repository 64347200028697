@import "../variables";

.swiper-button-next, .swiper-button-prev {
  //display: none !important;
  display: inline;
}

.swiper-pagination-bullet-active {
  width: 16px !important;
  height: 16px !important;
  //border-color: transparent !important;
  background: #2E50E0 !important;
  border: 1px solid #2E50E0;
  opacity: 1 !important;
}

.swiper-pagination-bullet {
  width: 16px !important;
  height: 16px !important;
  background: #B3C0F4 !important;
  opacity: 1 !important;
}

.swiper-button-next, .swiper-button-prev {
  align-items: center;
  color: #08123A !important;
  cursor: pointer;
  display: flex;
  height: var(--swiper-navigation-size);
  justify-content: center;
  margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 10) !important;
  z-index: 10;
}

.swiper-pagination-bullet {
  width: 16px!important;
  height: 16px!important;
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: var(--swiper-pagination-bullet-inactive-color, #0F1F66);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, .2);
}
